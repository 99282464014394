/* eslint-disable */
// https://dev2.bhsn.it/api/aistudio/swagger-ui/index.html
import type { AxiosPromise, AxiosInstance } from 'axios';
import { toAxiosOptions, mergeObjects } from '@allibee/utils';

let axiosInstance: AxiosInstance;
let baseURL: string;

const serviceRoute = 'aistudio';
export function setupServiceInstance(callback: (serviceRoute: string) => { baseURL: string; instance: AxiosInstance }) {
    const result = callback(serviceRoute);
    axiosInstance = result.instance;
    baseURL = result.baseURL;
}

export function getBaseURL() {
    return baseURL;
}

/**
 * Chat 메시지 피드백 조회
 * @returns AistudioAiStudioResponseListChatMessageFeedbackDto OK
 * @throws ApiError
 */
export function listFeedback(
    {
        chatKey,
        chatMessageKey,
    }: {
        chatKey: string;
        chatMessageKey: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponseListChatMessageFeedbackDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/chats/{chatKey}/messages/{chatMessageKey}/feedback/v1',
                path: {
                    chatKey: chatKey,
                    chatMessageKey: chatMessageKey,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * Chat 메시지 피드백
 * @returns AistudioAiStudioResponse OK
 * @throws ApiError
 */
export function createFeedback(
    {
        chatKey,
        chatMessageKey,
        requestBody,
    }: {
        chatKey: string;
        chatMessageKey: string;
        requestBody: AistudioChatMessageFeedbackForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/chats/{chatKey}/messages/{chatMessageKey}/feedback/v1',
                path: {
                    chatKey: chatKey,
                    chatMessageKey: chatMessageKey,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * Chat 쿼리 v2
 * @returns AistudioChatQueryDto 결과를 실시간 event-stream으로 보낸다.
 * @throws ApiError
 */
export function processChatQueryV2(
    {
        requestBody,
    }: {
        requestBody: AistudioChatQueryForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioChatQueryDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/chats/query/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * Chat 쿼리 v1
 * @returns AistudioChatQueryDto 결과를 실시간 event-stream으로 보낸다.
 * @throws ApiError
 */
export function processChatQuery(
    {
        requestBody,
    }: {
        requestBody: AistudioChatQueryForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioChatQueryDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/chats/query/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * Chat 파일 OCR
 * @returns AistudioAiStudioResponseChatDocumentOcrResultDto OK
 * @throws ApiError
 */
export function ocrChatDocument(
    {
        collectionId,
        documentId,
        pageStart,
        pageEnd,
    }: {
        /**
         * 데이터베이스 ID
         */
        collectionId: string;
        /**
         * 도큐먼트 ID
         */
        documentId: string;
        /**
         * 시작 페이지
         */
        pageStart?: number;
        /**
         * 종료 페이지
         */
        pageEnd?: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponseChatDocumentOcrResultDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/chats/{collectionId}/{documentId}/ocr/v1',
                path: {
                    collectionId: collectionId,
                    documentId: documentId,
                },
                query: {
                    pageStart: pageStart,
                    pageEnd: pageEnd,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * Chat 파일 다운로드
 * @returns AistudioStreamingResponseBody OK
 * @throws ApiError
 */
export function downloadChatDocument(
    {
        collectionId,
        documentId,
        pageStart,
        pageEnd,
    }: {
        /**
         * 데이터베이스 ID
         */
        collectionId: string;
        /**
         * 도큐먼트 ID
         */
        documentId: string;
        /**
         * 시작 페이지
         */
        pageStart?: number;
        /**
         * 종료 페이지
         */
        pageEnd?: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioStreamingResponseBody> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/chats/{collectionId}/{documentId}/download/v1',
                path: {
                    collectionId: collectionId,
                    documentId: documentId,
                },
                query: {
                    pageStart: pageStart,
                    pageEnd: pageEnd,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * Chat 조회
 * @returns AistudioAiStudioResponseChatDetailDto OK
 * @throws ApiError
 */
export function getChat(
    {
        chatKey,
    }: {
        chatKey: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponseChatDetailDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/chats/{chatKey}/v1',
                path: {
                    chatKey: chatKey,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * Chat 삭제
 * @returns AistudioAiStudioResponse OK
 * @throws ApiError
 */
export function deleteChat(
    {
        chatKey,
    }: {
        chatKey: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/chats/{chatKey}/v1',
                path: {
                    chatKey: chatKey,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * Chat 페이징 조회
 * @returns AistudioAiStudioResponsePageChatDto OK
 * @throws ApiError
 */
export function pageChatByCondition(
    {
        updatedDateStart,
        updatedDateEnd,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 조회 시작일
         */
        updatedDateStart?: string;
        /**
         * 조회 종료일
         */
        updatedDateEnd?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponsePageChatDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/chats/v1',
                query: {
                    updatedDateStart: updatedDateStart,
                    updatedDateEnd: updatedDateEnd,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * Chat 유저의 가장 최근 필터 조회 V2
 * @returns AistudioAiStudioResponseChatRecentFilterDto OK
 * @throws ApiError
 */
export function getChatRecentFilter(_?: any, requestConfig?: RequestConfig): AxiosPromise<AistudioAiStudioResponseChatRecentFilterDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/chats/recent-filter/v2',
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * Chat 유저의 가장 최근 필터 조회
 * @returns AistudioAiStudioResponseChatFilterDto OK
 * @throws ApiError
 */
export function getRecentFilter(_?: any, requestConfig?: RequestConfig): AxiosPromise<AistudioAiStudioResponseChatFilterDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/chats/recent-filter/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns string OK
 * @throws ApiError
 */
export function hello(_?: any, requestConfig?: RequestConfig): AxiosPromise<string> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/hello',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자의 AI 프롬프트 수정
 * @returns AistudioAiStudioResponseUserAiPromptCreatedDto OK
 * @throws ApiError
 */
export function updateUserPrompt(
    {
        promptId,
        requestBody,
    }: {
        promptId: number;
        requestBody: AistudioUserAiPromptUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponseUserAiPromptCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/user/prompts/{promptId}/v1',
                path: {
                    promptId: promptId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자의 AI 프롬프트 조회
 * @returns AistudioAiStudioResponseListUserAiPromptDto OK
 * @throws ApiError
 */
export function getUserPrompts(
    {
        userId,
    }: {
        userId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponseListUserAiPromptDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/user/prompts/v1',
                query: {
                    userId: userId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자의 AI 프롬프트 생성
 * @returns AistudioAiStudioResponseUserAiPromptCreatedDto OK
 * @throws ApiError
 */
export function createUserPrompt(
    {
        requestBody,
    }: {
        requestBody: AistudioUserAiPromptCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponseUserAiPromptCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/user/prompts/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 카테고리 상세 조회
 * @returns AistudioAiStudioResponseAiCategoryDetailDto OK
 * @throws ApiError
 */
export function getCategory(
    {
        categoryId,
    }: {
        categoryId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponseAiCategoryDetailDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/categories/{categoryId}/v1',
                path: {
                    categoryId: categoryId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 카테고리 수정 (custom)
 * @returns AistudioAiStudioResponseAiCategoryDto OK
 * @throws ApiError
 */
export function updateCategory(
    {
        categoryId,
        requestBody,
    }: {
        categoryId: number;
        requestBody: AistudioAiCategoryUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponseAiCategoryDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/categories/{categoryId}/v1',
                path: {
                    categoryId: categoryId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 초안 생성하기
 * @returns AistudioAdvisoryDraftDtoV1 자문 초안 생성 결과를 event-stream으로 전송한다.
 * @throws ApiError
 */
export function generateAdvisoryDraft(
    {
        inquiryId,
        requestBody,
    }: {
        /**
         * 유사자문 질의 ID
         */
        inquiryId: number;
        requestBody: AistudioAdvisoryDraftFormV1;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAdvisoryDraftDtoV1> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/similar-advisory/inquires/{inquiryId}/drafts/v1',
                path: {
                    inquiryId: inquiryId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 유사자문질의 목록 조회하기(페이징)
 * @returns AistudioAiStudioResponsePageAdvisorySimpleDtoV1 OK
 * @throws ApiError
 */
export function pageAdvisories(
    {
        pageable,
        updatedDateStart,
        updatedDateEnd,
    }: {
        pageable: AistudioPageable;
        /**
         * 조회 시작일
         */
        updatedDateStart?: string;
        /**
         * 조회 종료일
         */
        updatedDateEnd?: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponsePageAdvisorySimpleDtoV1> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/similar-advisory/inquires/v1',
                query: {
                    updatedDateStart: updatedDateStart,
                    updatedDateEnd: updatedDateEnd,
                    pageable: pageable,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 유사자문 질의하기
 * @returns AistudioAdvisoryInquiryDtoV1 유사자문 질의 결과를 event-stream으로 전송한다.
 * @throws ApiError
 */
export function inquiryAdvisory(
    {
        requestBody,
    }: {
        requestBody: AistudioAdvisoryInquiryFormV1;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAdvisoryInquiryDtoV1> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/similar-advisory/inquires/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 자문 초안을 워드로 다운로드하기
 * @returns AistudioStreamingResponseBody OK
 * @throws ApiError
 */
export function downloadDraft(
    {
        requestBody,
    }: {
        requestBody: AistudioDraftDownloadFormV1;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioStreamingResponseBody> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/similar-advisory/download-draft/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 카테고리 내 쿼리 검색
 * @returns AistudioAiStudioResponsePageAiCategorySearchQueryDto OK
 * @throws ApiError
 */
export function searchQuery(
    {
        categoryId,
        requestBody,
        page,
        size = 10,
        sort,
    }: {
        categoryId: number;
        requestBody: AistudioAiCategorySearchQueryForm;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponsePageAiCategorySearchQueryDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/categories/{categoryId}/query/v1',
                path: {
                    categoryId: categoryId,
                },
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 파일 업로드
 * @returns AistudioAiStudioResponseAiCategoryDocumentUploadedDto OK
 * @throws ApiError
 */
export function uploadDocument(
    {
        categoryId,
        formData,
    }: {
        /**
         * 카테고리 ID
         */
        categoryId: number;
        formData?: {
            form: AistudioAiCategoryDocumentCreateForm;
            /**
             * file
             */
            file: Blob;
        };
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponseAiCategoryDocumentUploadedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/categories/{categoryId}/documents/by-file/v1',
                path: {
                    categoryId: categoryId,
                },
                formData: formData,
                mediaType: 'multipart/form-data',
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 워크스페이스별 카테고리 목록 조회
 * @returns AistudioAiStudioResponseListAiCategoryDto OK
 * @throws ApiError
 */
export function getWorkspaceCategories(_?: any, requestConfig?: RequestConfig): AxiosPromise<AistudioAiStudioResponseListAiCategoryDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/categories/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 카테고리 생성 (custom)
 * @returns AistudioAiStudioResponseAiCategoryDto OK
 * @throws ApiError
 */
export function createCategory(
    {
        requestBody,
    }: {
        requestBody: AistudioAiCategoryCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponseAiCategoryDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/categories/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스의 국가별 태그 목록 조회
 * @returns AistudioAiStudioResponseListLocalizedAiTagDto OK
 * @throws ApiError
 */
export function getWorkspaceTags(_?: any, requestConfig?: RequestConfig): AxiosPromise<AistudioAiStudioResponseListLocalizedAiTagDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/tags/v2',
            }),
            requestConfig,
        ),
    );
}
/**
 * 유사자문질의 상세 조회하기
 * @returns AistudioAiStudioResponseAdvisoryDtoV1 OK
 * @throws ApiError
 */
export function getAdvisory(
    {
        inquiryId,
    }: {
        inquiryId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponseAdvisoryDtoV1> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/similar-advisory/inquires/{inquiryId}/v1',
                path: {
                    inquiryId: inquiryId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 워크스페이스별 AI 모델 목록 조회
 * @returns AistudioAiStudioResponseListAiModelDto OK
 * @throws ApiError
 */
export function getWorkspaceModels(_?: any, requestConfig?: RequestConfig): AxiosPromise<AistudioAiStudioResponseListAiModelDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/models/v2',
            }),
            requestConfig,
        ),
    );
}
/**
 * LLM 호출량 조회
 * @returns AistudioAiStudioResponseListDashboardLlmDto OK
 * @throws ApiError
 */
export function getLlmSummary(
    {
        dateStart,
        dateEnd,
    }: {
        /**
         * 조회 시작일
         */
        dateStart?: string;
        /**
         * 조회 종료일
         */
        dateEnd?: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponseListDashboardLlmDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/dashboards/llm/v1',
                query: {
                    dateStart: dateStart,
                    dateEnd: dateEnd,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 설정 조회
 * @returns AistudioAiStudioResponseWorkspaceConfigDto OK
 * @throws ApiError
 */
export function getConfig(_?: any, requestConfig?: RequestConfig): AxiosPromise<AistudioAiStudioResponseWorkspaceConfigDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/configs/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 파일 처리 상태 확인
 * @returns AistudioAiStudioResponseAiCategoryDocumentStatusDto OK
 * @throws ApiError
 */
export function getDocumentStatus(
    {
        categoryId,
        documentId,
    }: {
        /**
         * 카테고리 ID
         */
        categoryId: number;
        /**
         * 도큐먼트 ID
         */
        documentId: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponseAiCategoryDocumentStatusDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/categories/{categoryId}/documents/{documentId}/status/v1',
                path: {
                    categoryId: categoryId,
                    documentId: documentId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 파일 다운로드
 * @returns AistudioStreamingResponseBody OK
 * @throws ApiError
 */
export function downloadDocument(
    {
        categoryId,
        documentId,
    }: {
        /**
         * 카테고리 ID
         */
        categoryId: number;
        /**
         * 도큐먼트 ID
         */
        documentId: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioStreamingResponseBody> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/categories/{categoryId}/documents/{documentId}/download/v1',
                path: {
                    categoryId: categoryId,
                    documentId: documentId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 파일 목록 조회 (paging)
 * @returns AistudioAiStudioResponsePageAiCategoryDocumentDto OK
 * @throws ApiError
 */
export function pageDocuments(
    {
        categoryId,
        pageable,
    }: {
        /**
         * 카테고리 ID
         */
        categoryId: number;
        pageable: AistudioPageable;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponsePageAiCategoryDocumentDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/categories/{categoryId}/documents/v1',
                path: {
                    categoryId: categoryId,
                },
                query: {
                    pageable: pageable,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 파일 삭제
 * @returns AistudioAiStudioResponse OK
 * @throws ApiError
 */
export function deleteDocuments(
    {
        categoryId,
        requestBody,
    }: {
        /**
         * 카테고리 ID
         */
        categoryId: number;
        requestBody: AistudioAiCategoryDocumentDeleteForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/categories/{categoryId}/documents/v1',
                path: {
                    categoryId: categoryId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스별 카테고리 목록 조회 V2
 * @returns AistudioAiStudioResponseListAiCategoryWithTagDto OK
 * @throws ApiError
 */
export function getWorkspaceCategoriesV2(_?: any, requestConfig?: RequestConfig): AxiosPromise<AistudioAiStudioResponseListAiCategoryWithTagDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/categories/v2',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스의 세션용 카테고리 조회
 * @returns AistudioAiStudioResponseAiCategoryDto OK
 * @throws ApiError
 */
export function getSessionCategory(_?: any, requestConfig?: RequestConfig): AxiosPromise<AistudioAiStudioResponseAiCategoryDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/categories/session/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 카테고리 목록 페이징 조회
 * @returns AistudioAiStudioResponsePageAiCategoryOverviewDto OK
 * @throws ApiError
 */
export function pageByCondition(
    {
        searchKeyword,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponsePageAiCategoryOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/categories/search/v1',
                query: {
                    searchKeyword: searchKeyword,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 카테고리 메타 조회
 * @returns AistudioAiStudioResponseMapStringListObject OK
 * @throws ApiError
 */
export function getCategoryMeta(_?: any, requestConfig?: RequestConfig): AxiosPromise<AistudioAiStudioResponseMapStringListObject> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/categories/meta/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * Chat 메시지 조회
 * @returns AistudioAiStudioResponseSuperAdminChatMessageDetailDto OK
 * @throws ApiError
 */
export function getChatMessage(
    {
        chatMessageId,
    }: {
        chatMessageId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponseSuperAdminChatMessageDetailDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/chats/messages/{chatMessageId}/v1',
                path: {
                    chatMessageId: chatMessageId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 메시지 목록 조회
 * @returns AistudioAiStudioResponsePageSuperAdminChatMessageDto OK
 * @throws ApiError
 */
export function pageChatMessagesByCondition(
    {
        updatedDateStart,
        updatedDateEnd,
        workspaceId,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 조회 시작일
         */
        updatedDateStart: string;
        /**
         * 조회 종료일
         */
        updatedDateEnd: string;
        /**
         * 워크스페이스 ID
         */
        workspaceId?: number;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponsePageSuperAdminChatMessageDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/chats/messages/v1',
                query: {
                    updatedDateStart: updatedDateStart,
                    updatedDateEnd: updatedDateEnd,
                    workspaceId: workspaceId,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스의 태그 목록 조회
 * @returns AistudioAiStudioResponseListSuperAdminWorkspaceAiTagDto OK
 * @throws ApiError
 */
export function getTagsByWorkspace(
    {
        workspaceId,
        serviceType,
        country,
        tagType,
    }: {
        /**
         * 워크스페이스 ID
         */
        workspaceId: number;
        /**
         * 서비스 타입
         */
        serviceType: string;
        /**
         * 국가
         */
        country: string;
        /**
         * 태그 타입
         */
        tagType: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponseListSuperAdminWorkspaceAiTagDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/tags/by-workspace/v1',
                query: {
                    workspaceId: workspaceId,
                    serviceType: serviceType,
                    country: country,
                    tagType: tagType,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스의 태그 목록 수정
 * @returns AistudioAiStudioResponse OK
 * @throws ApiError
 */
export function updateTagsByWorkspace(
    {
        requestBody,
    }: {
        requestBody: AistudioSuperAdminWorkspaceAiTagsUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/tags/by-workspace/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 프롬프트 수정
 * @returns AistudioAiStudioResponseSuperAdminWorkspaceAiPromptCreatedDto OK
 * @throws ApiError
 */
export function updateWorkspacePrompt(
    {
        promptId,
        requestBody,
    }: {
        promptId: number;
        requestBody: AistudioSuperAdminWorkspaceAiPromptUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponseSuperAdminWorkspaceAiPromptCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/prompts/{promptId}/v1',
                path: {
                    promptId: promptId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 설정 조회
 * @returns AistudioAiStudioResponseSuperAdminWorkspaceConfigDto OK
 * @throws ApiError
 */
export function getWorkspaceConfig(
    {
        tenantId,
        workspaceId,
    }: {
        tenantId: number;
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponseSuperAdminWorkspaceConfigDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/configs/v1',
                query: {
                    tenantId: tenantId,
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 설정 수정
 * @returns AistudioAiStudioResponseSuperAdminWorkspaceConfigUpdatedDto OK
 * @throws ApiError
 */
export function updateWorkspaceConfig(
    {
        requestBody,
    }: {
        requestBody: AistudioSuperAdminWorkspaceConfigUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponseSuperAdminWorkspaceConfigUpdatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/configs/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스의 카테고리 목록 조회
 * @returns AistudioAiStudioResponseListSuperAdminAiCategoryOverviewDto OK
 * @throws ApiError
 */
export function getCategoriesByWorkspace(
    {
        workspaceId,
        serviceType,
    }: {
        workspaceId: number;
        serviceType: 'GENERAL' | 'WORKSPACE' | 'SESSION';
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponseListSuperAdminAiCategoryOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/categories/by-workspace/v1',
                query: {
                    workspaceId: workspaceId,
                    serviceType: serviceType,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스의 카테고리 목록 수정
 * @returns AistudioAiStudioResponse OK
 * @throws ApiError
 */
export function updateCategoriesByWorkspace(
    {
        requestBody,
    }: {
        requestBody: AistudioSuperAdminWorkspaceAiCategoryUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/categories/by-workspace/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 프롬프트 조회
 * @returns AistudioAiStudioResponseListSuperAdminWorkspaceAiPromptDto OK
 * @throws ApiError
 */
export function getWorkspacePrompts(
    {
        workspaceId,
    }: {
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponseListSuperAdminWorkspaceAiPromptDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/prompts/v1',
                query: {
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 프롬프트 생성
 * @returns AistudioAiStudioResponseSuperAdminWorkspaceAiPromptCreatedDto OK
 * @throws ApiError
 */
export function createWorkspacePrompt(
    {
        requestBody,
    }: {
        requestBody: AistudioSuperAdminWorkspaceAiPromptCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponseSuperAdminWorkspaceAiPromptCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admin/prompts/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 가능한 태그 목록 조회
 * @returns AistudioAiStudioResponseListSuperAdminAiTagDto OK
 * @throws ApiError
 */
export function getAvailableTags(
    {
        serviceType,
        country,
        tagType,
    }: {
        /**
         * 서비스 타입
         */
        serviceType: string;
        /**
         * 국가
         */
        country: string;
        /**
         * 태그 타입
         */
        tagType: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AistudioAiStudioResponseListSuperAdminAiTagDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/tags/v1',
                query: {
                    serviceType: serviceType,
                    country: country,
                    tagType: tagType,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 모델 목록 조회
 * @returns AistudioAiStudioResponseListAiModelDto OK
 * @throws ApiError
 */
export function getAvailableModels(_?: any, requestConfig?: RequestConfig): AxiosPromise<AistudioAiStudioResponseListAiModelDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/models/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 카테고리 서비스 타입 제어 가능 옵션 목록
 * @returns AistudioAiStudioResponseListEnumDto OK
 * @throws ApiError
 */
export function getAvailableCategoryTypes(_?: any, requestConfig?: RequestConfig): AxiosPromise<AistudioAiStudioResponseListEnumDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/configs/meta/categories/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 카테고리 메타 조회
 * @returns AistudioAiStudioResponseMapStringListObject OK
 * @throws ApiError
 */
export function getGlobalCategoryMeta(_?: any, requestConfig?: RequestConfig): AxiosPromise<AistudioAiStudioResponseMapStringListObject> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/categories/meta/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 설정 가능한 외부 DB 카테고리 목록 조회
 * @returns AistudioAiStudioResponseListSuperAdminAiCategoryOverviewDto OK
 * @throws ApiError
 */
export function getAvailableGeneralCategories(_?: any, requestConfig?: RequestConfig): AxiosPromise<AistudioAiStudioResponseListSuperAdminAiCategoryOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/categories/general/v1',
            }),
            requestConfig,
        ),
    );
}
