import { Node, mergeAttributes, type Attribute } from '@tiptap/core';
import { Fragment } from '@tiptap/pm/model';
import type { NodeType, Node as ProseMirrorNode, ResolvedPos } from '@tiptap/pm/model';
import { Plugin } from '@tiptap/pm/state';
import { SvelteNodeViewRenderer } from 'svelte-tiptap';

import BhsnReferenceComponent from './BhsnReferenceComponent.svelte';

// getBhsnInlineReferenceItems 메서드만을 위한 타입
export type BhsnInlineReferenceItem = {
    id: string;
    name: string;
    content: Fragment;
    size: number;
    text: string;
};

// referenceNode가 갖는 attr. Node.create호출시 제네릭이나 기타방법으로 전달 불가하여 문서화를 위해 타입정의
export type BhsnReferenceNodeAttributes = {
    'data-id': Attribute;
    'data-name': Attribute;
    'data-type': Attribute;
    'data-meta': Attribute;
    'focused-input': Attribute;
};

export interface BhsnReferenceOptions {
    HTMLAttributes: Record<string, unknown>;
}
declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        reference: {
            toggleReference: () => ReturnType;
            updateReferenceContent: (attributes: Array<{ id: string; value?: string | ProseMirrorNode[] }>) => ReturnType;
            focusedInputReferenceContent: (id: string, scrollInto?: boolean, scrollIntoOption?: ScrollIntoViewOptions) => ReturnType;
            scrollIntoFocusedInputReferenceContent: (id: string, option?: ScrollIntoViewOptions) => ReturnType;
            blurInputReferenceContent: () => ReturnType;
            getBhsnInlineReferenceItems: (resolveCallback: (data: BhsnInlineReferenceItem[]) => void) => ReturnType;
            removeReferences: (names: string[]) => ReturnType;
            updateReferenceAttributes: ({ filterFn, transform }: { filterFn: (node: ProseMirrorNode) => boolean; transform: (node: ProseMirrorNode) => ProseMirrorNode['attrs'] }) => ReturnType;
            replaceWithReferenceNode: (config: Record<string, unknown>) => ReturnType;
        };
    }
}

export const BhsnReferenceExtension = Node.create<BhsnReferenceOptions>({
    name: 'reference',
    group: 'inline',
    content: 'inline*',
    atom: true,
    inline: true,
    draggable: true,

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },

    addAttributes() {
        return {
            'data-id': {
                default: '',
            },
            'data-name': {
                default: '',
            },
            'data-type': {
                default: undefined,
            },
            'data-meta': {
                default: '',
            },
            'focused-input': {
                default: undefined,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'bhsn-reference',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['bhsn-reference', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },

    addCommands() {
        return {
            toggleReference:
                () =>
                ({ tr, state, dispatch }) => {
                    const { selection, schema, doc } = state;
                    const { from, to } = selection;
                    const referenceNodeType = schema.nodes.reference;

                    const referenceNodePositions: number[] = [];
                    state.doc.nodesBetween(from, to, (node, pos) => {
                        if (node.type === referenceNodeType) {
                            referenceNodePositions.push(pos);
                        }
                    });

                    if (referenceNodePositions.length) {
                        // 'reference' 노드를 텍스트로 변환
                        referenceNodePositions.forEach(pos => {
                            const node = tr.doc.nodeAt(pos);
                            const textContent = node!.attrs['data-id'];
                            tr.replaceWith(pos, pos + node!.nodeSize, schema.text(textContent, node!.marks));
                        });
                    } else {
                        // 선택된 텍스트를 가져와 'data-id' 속성에 설정한 뒤 'reference' 노드로 변환
                        const textContent = doc.textBetween(from, to);
                        const referenceNode = referenceNodeType.create({ 'data-id': textContent, 'data-name': textContent });
                        tr.replaceWith(from, to, referenceNode);
                    }

                    tr.setMeta('changedBhsnReference', true);
                    if (dispatch) dispatch(tr);
                    return true;
                },
            // 특정 id를 포함하는 값을 받았을때 data-id이 같은 값을 child content로 추가합니다.
            updateReferenceContent:
                attributes =>
                ({ tr, state, dispatch }) => {
                    const { doc, schema } = state;
                    const referenceNodeType = schema.nodes.reference;
                    const nodeInfos: Array<{ node: ProseMirrorNode; pos: number }> = [];

                    doc.descendants((node, pos) => {
                        if (node.type === referenceNodeType) {
                            if (attributes.some(attr => attr.id === node.attrs['data-id'] || attr.id === node.attrs['data-name'])) {
                                nodeInfos.push({ node, pos });
                            }
                        }
                    });

                    // 위치 문제를 피하기 위해 reverse 순서로 처리합니다.
                    for (let i = nodeInfos.length - 1; i >= 0; i--) {
                        const { node, pos } = nodeInfos[i];
                        const { value } = attributes.find(attr => attr.id === node.attrs['data-id'] || attr.id === node.attrs['data-name']) || {};

                        let fragment: Fragment;
                        if (value) {
                            let nodes: ProseMirrorNode[] = [];
                            if (typeof value === 'string') {
                                const parts = value.split('\n');
                                parts.forEach((part, index) => {
                                    if (part) {
                                        nodes.push(schema.text(part, node.marks));
                                    }
                                    if (index < parts.length - 1) {
                                        nodes.push(schema.nodes.hardBreak.create());
                                    }
                                });
                            } else if (Array.isArray(value)) {
                                nodes = value.flatMap((rawNode: unknown) => {
                                    // 이미 ProseMirror Node 인스턴스인지 판단 (toJSON이 있다면 대부분 Node 인스턴스임)
                                    let pmNode: ProseMirrorNode;
                                    if (rawNode && typeof rawNode.toJSON === 'function') {
                                        pmNode = rawNode;
                                    } else {
                                        pmNode = schema.nodeFromJSON(rawNode);
                                    }
                                    // 만약 paragraph와 같이 block 노드라면,
                                    // 내부의 inline 콘텐츠만 추출해서 text 노드로 변환합니다.
                                    if (pmNode.type.name === 'paragraph' && pmNode.content.size) {
                                        return pmNode.content.content.map(child => {
                                            // child가 text 노드면 그대로 텍스트 노드 생성
                                            if (child.type.name === 'text') {
                                                return schema.text(child.text, child.marks);
                                            }
                                            // 다른 inline 노드가 있다면 JSON을 통해 다시 생성
                                            return schema.nodeFromJSON(child.toJSON());
                                        });
                                    }
                                    return [pmNode];
                                });
                                // 노드의 개수가 1 이상이면, 각 노드 사이에 ','를 텍스트로 갖는 text node를 삽입합니다.
                                if (nodes.length > 1) {
                                    const nodesWithCommas: ProseMirrorNode[] = [];
                                    nodes.forEach((node, index) => {
                                        nodesWithCommas.push(node);
                                        if (index < nodes.length - 1) {
                                            nodesWithCommas.push(schema.text(', ', []));
                                        }
                                    });
                                    nodes = nodesWithCommas;
                                }
                            }
                            fragment = Fragment.from(nodes);
                        } else {
                            fragment = Fragment.empty;
                        }

                        const updatedNode = node.copy(fragment);
                        tr.replaceWith(pos, pos + node.nodeSize, updatedNode);
                    }

                    tr.setMeta('changedBhsnReference', true);
                    if (dispatch) dispatch(tr);
                    return true;
                },
            // Input 영역에서 data-id에 해당하는 reference를 focus했을때 기존 focused-input를 모두 제거하고 해당 reference 에 focused-input attr을 추가합니다.
            focusedInputReferenceContent:
                (id, scrollInto = false, scrollIntoOption = { behavior: 'smooth', block: 'center' }) =>
                ({ tr, view, state, dispatch }) => {
                    const { doc, schema } = state;
                    const referenceNodeType = schema.nodes.reference;
                    let referenceNodePosition: number | null = null;

                    // 선택한 reference 노드에만 'focused-input' 속성을 추가합니다.
                    doc.descendants((node, pos) => {
                        if (node.type === referenceNodeType) {
                            const { attrs } = node;
                            if (id && id === node.attrs['data-id']) {
                                tr.setNodeMarkup(pos, null, { ...attrs, 'focused-input': true });
                                if (referenceNodePosition === null) referenceNodePosition = pos;
                            } else {
                                tr.setNodeMarkup(pos, null, { ...attrs, 'focused-input': undefined });
                            }
                        }
                    });

                    if (scrollInto && referenceNodePosition !== null) {
                        const referenceNodeView = view.nodeDOM(referenceNodePosition);
                        if (referenceNodeView) {
                            setTimeout(() => {
                                (referenceNodeView as Element).scrollIntoView(scrollIntoOption);
                            });
                        }
                    }
                    if (dispatch) dispatch(tr);
                    return true;
                },

            scrollIntoFocusedInputReferenceContent:
                (id, option = { behavior: 'smooth', block: 'center' }) =>
                ({ view, state: { doc, schema } }) =>
                    doc.descendants((node, pos) => {
                        if (node.type === schema.nodes.reference && id === node.attrs['data-id']) {
                            setTimeout(() => (view.nodeDOM(pos) as Element)?.scrollIntoView(option));
                            return false;
                        }
                    }) ?? true,
            // Input 영역에서 data-id에 해당하는 reference를 blur했을때 기존 focused-input를 모두 제거
            blurInputReferenceContent:
                () =>
                ({ tr, state, dispatch }) => {
                    const { doc, schema } = state;
                    const referenceNodeType = schema.nodes.reference;

                    // 'focused-input' 속성을 모두 제거합니다.
                    doc.descendants((node, pos) => {
                        if (node.type === referenceNodeType) {
                            const { attrs } = node;
                            const updatedAttrs = { ...attrs, 'focused-input': undefined };
                            tr.setNodeMarkup(pos, null, updatedAttrs);
                        }
                    });
                    if (dispatch) dispatch(tr);
                    return true;
                },
            getBhsnInlineReferenceItems:
                (resolveCallback = () => {}) =>
                ({ state }) => {
                    const { doc, schema } = state;
                    const referenceNodeType = schema.nodes.reference;
                    const references: BhsnInlineReferenceItem[] = [];

                    doc.descendants(node => {
                        if (node.type === referenceNodeType) {
                            references.push({
                                id: node.attrs['data-id'],
                                name: node.attrs['data-name'],
                                content: node.content,
                                size: node.content.size,
                                text: node.textContent,
                            });
                        }
                    });
                    resolveCallback(references);
                    return true;
                },
            removeReferences:
                (names: string[]) =>
                ({ state, tr }) => {
                    const positions: Array<[number, number]> = [];

                    state.doc.descendants((node, pos) => {
                        if (node.type === state.schema.nodes.reference && names.includes(node.attrs['data-name'])) {
                            positions.push([pos, node.nodeSize]);
                        }
                    });
                    positions.toReversed().forEach(([pos, size]) => tr.delete(pos, pos + size));

                    return true;
                },
            updateReferenceAttributes:
                ({ filterFn, transform }: { filterFn: (node: ProseMirrorNode) => boolean; transform: (node: ProseMirrorNode) => ProseMirrorNode['attrs'] }) =>
                ({ tr, state, dispatch, editor }) => {
                    if (!filterFn) return true;

                    const updates: Array<{ pos: number; node: ProseMirrorNode; size: number }> = [];

                    state.doc.descendants((node, pos) => {
                        if (filterFn(node) && node.type === state.schema.nodes.reference) {
                            updates.push({ pos, node, size: node.nodeSize });
                        }
                    });

                    updates.reverse().forEach(({ pos, node }) => {
                        const newAttrs = transform(node);
                        const newNode = state.schema.nodes.reference.create(newAttrs, editor.state.schema.text(newAttrs['data-name']));
                        tr.replaceWith(pos, pos + node.nodeSize, newNode);
                    });

                    if (dispatch) dispatch(tr);
                    return true;
                },

            replaceWithReferenceNode:
                (config: Record<string, unknown>) =>
                ({ tr, dispatch, editor }) => {
                    const node = editor.state.schema.nodes.reference.create(
                        {
                            'data-id': config.id,
                            'data-name': config.name,
                            'data-type': config.type,
                            'data-meta': JSON.stringify(config),
                        },
                        editor.state.schema.text(config.name as string),
                    );
                    tr.replaceSelectionWith(node);
                    if (dispatch) dispatch(tr);
                    return true;
                },
        };
    },
    addNodeView() {
        return SvelteNodeViewRenderer(BhsnReferenceComponent);
    },
    addProseMirrorPlugins() {
        return [
            new Plugin({
                props: {
                    handleDrop: (view, event, slice, moved) => {
                        const data = event.dataTransfer?.getData('application/json');

                        const payload = data && JSON.parse(data);
                        if (!payload) return false;
                        if (payload?.referenceType !== 'inline') return false;

                        const coordinates = view.posAtCoords({
                            left: event.clientX,
                            top: event.clientY,
                        });

                        if (!coordinates) return false;

                        const { tr, schema, doc } = view.state;
                        const textNode = schema.text(payload.name, []);

                        const parentNode = findParentByNodeTypeName(doc.resolve(coordinates.pos), 'reference');
                        if (moved) return false;

                        if (parentNode) return false;

                        const referenceNode = schema.nodes.reference.create(
                            {
                                'data-id': payload.name,
                                'data-name': payload.name,
                                'data-type': payload.type,
                                'data-meta': JSON.stringify(payload),
                            },
                            textNode,
                        );

                        tr.replaceWith(coordinates.pos, coordinates.pos, referenceNode).setMeta('changedBhsnReference', true);

                        view.dispatch(tr);
                        return true;
                    },
                },
            }),
        ];
    },
});

/**
 * pos 위치로부터 상위 노드를 탐색하여 typeName과 일치하는 노드가 있는지 확인합니다.
 */
const findParentByNodeTypeName = (pos: ResolvedPos, typeName: string) => {
    let depth = pos.depth;

    while (depth > 0) {
        if (pos.node(depth).type.name === typeName) return true;
        depth--;
    }

    return false;
};
