import { Extension } from '@tiptap/core';
import { wordStarterPackExtensions } from './wordStarterPackExtensions';
import { createWordTrackChangeExtensionInstance } from '../extension-word-track-change';

export interface WordStarterPackOptions {
    [key: string]: unknown;
}

const WordStarterPack = Extension.create<WordStarterPackOptions>({
    name: 'wordStarterPack',

    addExtensions() {
        return [...wordStarterPackExtensions, createWordTrackChangeExtensionInstance()];
    },
});

export default WordStarterPack;
