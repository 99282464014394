/* eslint-disable */
// https://dev2.bhsn.it/api/aireview/swagger-ui/index.html
import type { AxiosPromise, AxiosInstance } from 'axios';
import { toAxiosOptions, mergeObjects } from '@allibee/utils';

let axiosInstance: AxiosInstance;
let baseURL: string;

const serviceRoute = 'aireview';
export function setupServiceInstance(callback: (serviceRoute: string) => { baseURL: string; instance: AxiosInstance }) {
    const result = callback(serviceRoute);
    axiosInstance = result.instance;
    baseURL = result.baseURL;
}

export function getBaseURL() {
    return baseURL;
}

/**
 * Edit Clause v1
 * @returns AireviewResponseEditClauseDto OK
 * @throws ApiError
 */
export function postEditClauseV1(
    {
        requestBody,
    }: {
        requestBody: AireviewEditClauseForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseEditClauseDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/edit-clause/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * Review Chat v2. SSE(Server-Sent Events)
 * @returns AireviewReviewChatDtoV2 결과를 실시간 event-stream으로 보낸다.
 * @throws ApiError
 */
export function postReviewChatV2(
    {
        requestBody,
    }: {
        requestBody: AireviewReviewChatForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewReviewChatDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/review-chat/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * Review Chat SSE(Server-Sent Events) 방식.
 * @returns string 결과를 실시간 event-stream으로 보낸다.
 * @throws ApiError
 */
export function postReviewChatV1(
    {
        requestBody,
    }: {
        requestBody: AireviewReviewChatForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<Array<string>> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/review-chat/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 쿼리 컨펌 v2
 * @returns AireviewResponseQueryConfirmDto OK
 * @throws ApiError
 */
export function postCheckpointV2(
    {
        requestBody,
    }: {
        requestBody: AireviewQueryConfirmForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseQueryConfirmDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/review-chat/query-confirm/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * Review Clause Analysis SSE(Server-Sent Events) 방식.
 * @returns string 결과를 실시간 event-stream으로 보낸다.
 * @throws ApiError
 */
export function postReviewClauseAnalysis(
    {
        requestBody,
    }: {
        requestBody: AireviewReviewClauseAnalysisForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<Array<string>> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/review-clause-analysis/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 파일 리뷰 v2
 * @returns AireviewResponseReviewDto OK
 * @throws ApiError
 */
export function reviewContractFileV2(
    {
        formData,
    }: {
        formData?: {
            form: AireviewFileReviewFormV2;
            /**
             * 계약서 파일
             */
            file: Blob;
        };
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseReviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/review/contract-file/v2',
                formData: formData,
                mediaType: 'multipart/form-data',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 파일 리뷰 v1
 * @returns AireviewResponseReviewDto OK
 * @throws ApiError
 */
export function reviewContractFileV1(
    {
        formData,
    }: {
        formData?: {
            form: AireviewFileReviewForm;
            /**
             * 계약서 파일
             */
            file: Blob;
        };
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseReviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/review/contract-file/v1',
                formData: formData,
                mediaType: 'multipart/form-data',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 document(json) 리뷰 v4
 * @returns AireviewResponseReviewDtoV2 OK
 * @throws ApiError
 */
export function reviewContractDocumentV4(
    {
        requestBody,
    }: {
        requestBody: AireviewDocumentReviewFormV4;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseReviewDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/review/contract-document/v4',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 document(json) 리뷰 v3
 * @returns AireviewResponseReviewDtoV2 OK
 * @throws ApiError
 */
export function reviewContractDocumentV3(
    {
        requestBody,
    }: {
        requestBody: AireviewDocumentReviewFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseReviewDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/review/contract-document/v3',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 document(json) 리뷰 v2
 * @returns AireviewResponseReviewDtoV2 OK
 * @throws ApiError
 */
export function reviewContractDocumentV2(
    {
        requestBody,
    }: {
        requestBody: AireviewDocumentReviewForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseReviewDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/review/contract-document/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 document(json) 리뷰 v1
 * @returns AireviewResponseReviewDto OK
 * @throws ApiError
 */
export function reviewContractDocumentV1(
    {
        requestBody,
    }: {
        requestBody: AireviewDocumentReviewForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseReviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/review/contract-document/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 document(json) 리뷰 Cache v2
 * @returns AireviewResponseReviewDtoV2 OK
 * @throws ApiError
 */
export function reviewContractDocumentByCacheV2(
    {
        requestBody,
    }: {
        requestBody: AireviewDocumentReviewCacheFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseReviewDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/review/contract-document/by-cache/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 document(json) 리뷰 Cache v1
 * @returns AireviewResponseReviewDtoV2 OK
 * @throws ApiError
 */
export function reviewContractDocumentByCacheV1(
    {
        requestBody,
    }: {
        requestBody: AireviewDocumentReviewCacheForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseReviewDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/review/contract-document/by-cache/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 체크포인트 v4 (체크포인트별 결과)
 * @returns AireviewResponseListCheckClauseResultDto OK
 * @throws ApiError
 */
export function postCheckpointV4(
    {
        requestBody,
    }: {
        requestBody: AireviewCheckpointFormV4;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseListCheckClauseResultDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/checkpoints/v4',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 번역 SSE(Server-Sent Events) 방식.
 * @returns string 결과를 실시간 event-stream으로 보낸다.
 * @throws ApiError
 */
export function postTranslationV1(
    {
        requestBody,
    }: {
        requestBody: AireviewTranslationForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<Array<string>> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/translations/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 기본 정보 추출 v3
 * @returns AireviewResponseContractMetadataDtoV2 OK
 * @throws ApiError
 */
export function predictContractV3(
    {
        requestBody,
    }: {
        requestBody: AireviewContractPredictByFileKeyForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseContractMetadataDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contract-prediction/v3',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 가장 적합한 기본 정보 추출 (basic) v1
 * @returns AireviewResponseMostFitContractBasicMetadataDto OK
 * @throws ApiError
 */
export function predictMostFitContractBasic(
    {
        requestBody,
    }: {
        requestBody: AireviewDocument;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseMostFitContractBasicMetadataDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/contract-prediction/most-fit/basic/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 계약서 유형 목록
 * @returns AireviewResponseListContractTypeDto OK
 * @throws ApiError
 */
export function contractTypesV2(_?: any, requestConfig?: RequestConfig): AxiosPromise<AireviewResponseListContractTypeDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contract-types/v2',
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 계약서 유형 목록
 * @returns AireviewResponseListContractTypeDto OK
 * @throws ApiError
 */
export function contractTypesV1(_?: any, requestConfig?: RequestConfig): AxiosPromise<AireviewResponseListContractTypeDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/contract-types/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 코드 목록
 * @returns AireviewResponseMapStringListEnumDto OK
 * @throws ApiError
 */
export function codesV2(_?: any, requestConfig?: RequestConfig): AxiosPromise<AireviewResponseMapStringListEnumDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/codes/v2',
            }),
            requestConfig,
        ),
    );
}
/**
 * 코드 목록
 * @returns AireviewResponseMapStringListEnumDto OK
 * @throws ApiError
 */
export function codesV1(_?: any, requestConfig?: RequestConfig): AxiosPromise<AireviewResponseMapStringListEnumDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/codes/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns string OK
 * @throws ApiError
 */
export function hello(_?: any, requestConfig?: RequestConfig): AxiosPromise<string> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/hello',
            }),
            requestConfig,
        ),
    );
}
/**
 * 라벨 체크포인트 목록 V2
 * @returns AireviewResponseListLabelCheckpointDto OK
 * @throws ApiError
 */
export function getLabelCheckpoints(
    {
        contractType,
        language,
        labelIds,
        includeClauseExamples,
    }: {
        /**
         * 계약 타입
         */
        contractType: string;
        /**
         * 언어
         */
        language: 'KO' | 'EN' | 'ETC';
        /**
         * 라벨 IDs
         */
        labelIds?: Array<number>;
        /**
         * 예시 조항 포함 여부
         */
        includeClauseExamples?: boolean;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseListLabelCheckpointDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/label-checkpoints/v2',
                query: {
                    labelIds: labelIds,
                    includeClauseExamples: includeClauseExamples,
                    contractType: contractType,
                    language: language,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 라벨 체크포인트 목록
 * @returns AireviewResponseListLabelCheckpointDto OK
 * @throws ApiError
 */
export function getLabelCheckpoints1(
    {
        contractType,
        language,
        labelIds,
        includeClauseExamples,
    }: {
        /**
         * 계약 타입
         */
        contractType: string;
        /**
         * 언어
         */
        language: 'KO' | 'EN' | 'ETC';
        /**
         * 라벨 IDs
         */
        labelIds?: Array<number>;
        /**
         * 예시 조항 포함 여부
         */
        includeClauseExamples?: boolean;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseListLabelCheckpointDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/label-checkpoints/v1',
                query: {
                    labelIds: labelIds,
                    includeClauseExamples: includeClauseExamples,
                    contractType: contractType,
                    language: language,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 유사 조항 목록
 * @returns AireviewResponseListString OK
 * @throws ApiError
 */
export function getSimilarClauses(
    {
        requestBody,
    }: {
        requestBody: AireviewSimilarClauseCondition;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseListString> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/similar-clauses/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 새 버전 저장하기 v1
 * @returns AireviewResponse OK
 * @throws ApiError
 */
export function saveDocumentV1(
    {
        contractId,
        requestBody,
    }: {
        contractId: number;
        requestBody: AireviewContractDocumentSaveForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/standalone/contracts/{contractId}/document/v1',
                path: {
                    contractId: contractId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 클라우드 보관함 페이징 조회하기 v1
 * @returns AireviewResponsePageCloudContractDto OK
 * @throws ApiError
 */
export function pageCloudContractsV1(
    {
        searchKeyword,
        sortField,
        sortDirection,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * 정렬 기준
         */
        sortField?: 'CREATED_AT';
        /**
         * 정렬 방향
         */
        sortDirection?: 'ASC' | 'DESC';
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponsePageCloudContractDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/standalone/contracts/v1',
                query: {
                    searchKeyword: searchKeyword,
                    sortField: sortField,
                    sortDirection: sortDirection,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 생성하기 v1
 * @returns AireviewResponseContractCreatedDto OK
 * @throws ApiError
 */
export function createContractV1(
    {
        requestBody,
    }: {
        requestBody: AireviewContractCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseContractCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/standalone/contracts/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 문서 미리보기 (file to doumnet)
 * @returns AireviewResponseDocument OK
 * @throws ApiError
 */
export function getDocumentByFile(
    {
        fileKey,
        language,
    }: {
        /**
         * 계약서 파일 key
         */
        fileKey: string;
        /**
         * 계약서 언어(ISO Code: ko, en, etc)
         */
        language?: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseDocument> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/standalone/documents/by-file/v1',
                query: {
                    language: language,
                    fileKey: fileKey,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 조회하기 v1
 * @returns AireviewResponseStandaloneContractDto OK
 * @throws ApiError
 */
export function getContractV1(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseStandaloneContractDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/standalone/contracts/{contractId}/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 계약서 파일(docx) 다운로드하기 v1
 * @returns any OK
 * @throws ApiError
 */
export function downloadContractV1(
    {
        contractId,
    }: {
        contractId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/standalone/contracts/{contractId}/download/v1',
                path: {
                    contractId: contractId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * Workspace 내에 Contract Type 목록
 * @returns AireviewResponseListWorkspaceContractTypeDto OK
 * @throws ApiError
 */
export function getWorkspaceContractTypesV21(
    {
        workspaceId,
    }: {
        /**
         * 워크스페이스 ID
         */
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseListWorkspaceContractTypeDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/workspace-contract-types/v2',
                query: {
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * Workspace Contract Type 업데이트
 * @returns AireviewResponse OK
 * @throws ApiError
 */
export function putWorkspaceContractTypesV2(
    {
        requestBody,
    }: {
        requestBody: AireviewWorkspaceContractTypeFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/workspace-contract-types/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * Workspace 내 AI Provider Config 목록
 * @returns AireviewResponseListWorkspaceAiServiceConfigDto OK
 * @throws ApiError
 */
export function getWorkspaceAiServiceConfigs(
    {
        workspaceId,
    }: {
        /**
         * 워크스페이스 ID
         */
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponseListWorkspaceAiServiceConfigDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/workspace-ai-service-configs/v1',
                query: {
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * Workspace 내 AI Provider Config 업데이트
 * @returns AireviewResponse OK
 * @throws ApiError
 */
export function putWorkspaceAiServiceConfigs(
    {
        requestBody,
    }: {
        requestBody: AireviewWorkspaceAiServiceConfigUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/workspace-ai-service-configs/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 조항 예시 엑셀 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadClauseExampleExcel(
    {
        workspaceId,
    }: {
        /**
         * 워크스페이스 ID
         */
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/clause-examples/excel/v1',
                query: {
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 조항 예시 엑셀 업로드
 * @returns AireviewResponse OK
 * @throws ApiError
 */
export function updateClauseExamplesByExcel(
    {
        workspaceId,
        formData,
    }: {
        /**
         * 워크스페이스 ID
         */
        workspaceId: number;
        formData?: {
            file: Blob;
        };
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/clause-examples/excel/v1',
                query: {
                    workspaceId: workspaceId,
                },
                formData: formData,
                mediaType: 'multipart/form-data',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스별 라벨 체크포인트 엑셀 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadLabelCheckpointsExcel(
    {
        workspaceId,
    }: {
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/label-checkpoints/workspace/{workspaceId}/excel/v1',
                path: {
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스별 라벨 체크포인트 엑셀 업로드
 * @returns AireviewResponse OK
 * @throws ApiError
 */
export function uploadLabelCheckpointsExcel(
    {
        workspaceId,
        formData,
    }: {
        workspaceId: number;
        formData?: {
            file: Blob;
        };
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<AireviewResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admin/label-checkpoints/workspace/{workspaceId}/excel/v1',
                path: {
                    workspaceId: workspaceId,
                },
                formData: formData,
                mediaType: 'multipart/form-data',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자의 워크스페이스 내 Ai Service Config 조회
 * @returns AireviewResponseListWorkspaceAiServiceConfigDto OK
 * @throws ApiError
 */
export function getWorkspaceAiConfig(_?: any, requestConfig?: RequestConfig): AxiosPromise<AireviewResponseListWorkspaceAiServiceConfigDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workspace-ai-service-configs/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자의 워크스페이스 내 Contract Type 목록 v2
 * @returns AireviewResponseListWorkspaceContractTypeDto OK
 * @throws ApiError
 */
export function getWorkspaceContractTypesV3(_?: any, requestConfig?: RequestConfig): AxiosPromise<AireviewResponseListWorkspaceContractTypeDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workspace-contract-types/v3',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자의 워크스페이스 내 Contract Type 목록 v2
 * @returns AireviewResponseListContractTypeVo OK
 * @throws ApiError
 */
export function getWorkspaceContractTypesV2(_?: any, requestConfig?: RequestConfig): AxiosPromise<AireviewResponseListContractTypeVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workspace-contract-types/v2',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자의 워크스페이스 내 Contract Type 목록
 * @returns AireviewResponseListContractTypeVo OK
 * @throws ApiError
 */
export function getWorkspaceContractTypes(_?: any, requestConfig?: RequestConfig): AxiosPromise<AireviewResponseListContractTypeVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workspace-contract-types/v1',
            }),
            requestConfig,
        ),
    );
}
