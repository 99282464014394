import type { ComponentType } from 'svelte';

import type { TemplateFieldType } from '../../types';

import Calendar from './Calendar.svelte';
import Check from './Checkbox.svelte';
import Dollar from './Dollar.svelte';
import Number from './Number.svelte';
import Paragraph from './Paragraph.svelte';
import Radio from './Radio.svelte';
import Text from './Text.svelte';
import CounterPartyMappingField from './CounterPartyMappingField.svelte';

type TemplateFieldIconType = TemplateFieldType | 'mappingField';

export const templateFieldIconMap: Record<TemplateFieldIconType, { name: string; type: TemplateFieldIconType; Icon: ComponentType }> = {
    text: { name: '단문 텍스트', type: 'text', Icon: Text },
    textarea: { name: '장문 텍스트', type: 'textarea', Icon: Paragraph },
    number: { name: '숫자', type: 'number', Icon: Number },
    date: { name: '날짜', type: 'date', Icon: Calendar },
    price: { name: '금액', type: 'price', Icon: Dollar },
    radio: { name: '단일 선택', type: 'radio', Icon: Radio },
    check: { name: '복수 선택', type: 'check', Icon: Check },
    mappingField: { name: '매핑 필드', type: 'mappingField', Icon: CounterPartyMappingField },
} as const;
