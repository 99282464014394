<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...$$props}>
    <path
        d="M6.41689 15.2362C7.23583 15.5754 8.11358 15.75 9 15.75C10.7902 15.75 12.5071 15.0388 13.773 13.773C15.0388 12.5071 15.75 10.7902 15.75 9C15.75 7.20979 15.0388 5.4929 13.773 4.22703C12.5071 2.96116 10.7902 2.25 9 2.25C8.11358 2.25 7.23583 2.42459 6.41689 2.76381C5.59794 3.10303 4.85382 3.60023 4.22703 4.22703C3.60023 4.85382 3.10303 5.59794 2.76381 6.41689C2.42459 7.23583 2.25 8.11358 2.25 9C2.25 9.88642 2.42459 10.7642 2.76381 11.5831C3.10303 12.4021 3.60023 13.1462 4.22703 13.773C4.85382 14.3998 5.59794 14.897 6.41689 15.2362Z"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path d="M9.92969 6.34375V12H8.90625V7.33594H8.875L7.55469 8.17969V7.25L8.95312 6.34375H9.92969Z" fill="currentColor" />
</svg>
