/* eslint-disable */
// https://dev2.bhsn.it/api/mtm/swagger-ui/index.html
import type { AxiosPromise, AxiosInstance } from 'axios';
import { toAxiosOptions, mergeObjects } from '@allibee/utils';

let axiosInstance: AxiosInstance;
let baseURL: string;

const serviceRoute = 'mtm';
export function setupServiceInstance(callback: (serviceRoute: string) => { baseURL: string; instance: AxiosInstance }) {
    const result = callback(serviceRoute);
    axiosInstance = result.instance;
    baseURL = result.baseURL;
}

export function getBaseURL() {
    return baseURL;
}

/**
 * @returns MtmLissResponseApiKeyCreatedDto OK
 * @throws ApiError
 */
export function createApiKey(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseApiKeyCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/api-keys/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 결제/청구 관련 정보 조회(구독상태,정기결제,결제수단)
 * @returns MtmLissResponsePaymentOverviewDto OK
 * @throws ApiError
 */
export function getPaymentOverview(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponsePaymentOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/payments/overview/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateRequestApiKey(
    {
        requestBody,
    }: {
        requestBody: MtmApiRequestUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/external/openapi/request/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns MtmLissResponseLong OK
 * @throws ApiError
 */
export function requestApiKey(
    {
        requestBody,
    }: {
        requestBody: MtmApiRequestCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseLong> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/external/openapi/request/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns string OK
 * @throws ApiError
 */
export function processPaymentResult(
    {
        returnUrl,
        paymentResult,
    }: {
        returnUrl: string;
        paymentResult: MtmNicePayPaymentResult;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<string> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/payments/nicepay/callback/v1',
                query: {
                    returnUrl: returnUrl,
                    paymentResult: paymentResult,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 현재 워크스페이스 부가 정보 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateWorkspaceAdditionalInfo(
    {
        requestBody,
    }: {
        requestBody: MtmWorkspaceAdditionalInfoForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/workspaces/current/additional-info/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스의 현재 설정 목록 조회
 * @returns MtmLissResponseListWorkspaceConfigurationDto OK
 * @throws ApiError
 */
export function listWorkspaceConfigurations(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListWorkspaceConfigurationDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workspace-configurations/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 설정 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateUserWorkspaceConfiguration(
    {
        requestBody,
    }: {
        requestBody: MtmWorkspaceConfigurationUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/workspace-configurations/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 조회
 * @returns MtmLissResponseUserProfileDto OK
 * @throws ApiError
 */
export function getUser(
    {
        userId,
    }: {
        userId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseUserProfileDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/{userId}/v1',
                path: {
                    userId: userId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 수정
 * @returns MtmLissResponseUserUpdatedDto OK
 * @throws ApiError
 */
export function updatedUser(
    {
        userId,
        requestBody,
    }: {
        userId: number;
        requestBody: MtmUserUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseUserUpdatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/users/{userId}/v1',
                path: {
                    userId: userId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 역할 목록 조회
 * @returns MtmLissResponseListRoleDto OK
 * @throws ApiError
 */
export function getUserRoles(
    {
        userId,
    }: {
        userId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseListRoleDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/{userId}/roles/v1',
                path: {
                    userId: userId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 역할 벌크 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function putUserRoles(
    {
        userId,
        requestBody,
    }: {
        userId: number;
        requestBody: MtmUserRolesUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/users/{userId}/roles/v1',
                path: {
                    userId: userId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 역할 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function putUserRole(
    {
        userId,
        requestBody,
    }: {
        userId: number;
        requestBody: MtmUserRoleUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/users/{userId}/role/v1',
                path: {
                    userId: userId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 로케일 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function putUserPreference(
    {
        userId,
        requestBody,
    }: {
        userId: number;
        requestBody: MtmUserPreferenceForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/users/{userId}/preference/v1',
                path: {
                    userId: userId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자의 소속 그룹(GROUP) 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateUserGroup(
    {
        userId,
        requestBody,
    }: {
        userId: number;
        requestBody: MtmUserGroupForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/users/{userId}/groups/v1',
                path: {
                    userId: userId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자에 소속 그룹 할당
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function assignGroupsToUser(
    {
        userId,
        requestBody,
    }: {
        userId: number;
        requestBody: MtmUserGroupForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/users/{userId}/groups/v1',
                path: {
                    userId: userId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 게스트를 구성원으로 변경
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function convertGuestToMember(
    {
        userId,
        requestBody,
    }: {
        userId: number;
        requestBody: MtmUserRolesUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/users/{userId}/convert-guest/v1',
                path: {
                    userId: userId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자의 인사 정보 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateUserAffiliations(
    {
        userId,
        requestBody,
    }: {
        userId: number;
        requestBody: MtmUserAffiliationUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/users/{userId}/affiliations/v1',
                path: {
                    userId: userId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 자격 정보 강제 초기화
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function forceResetUserCredential(
    {
        requestBody,
    }: {
        requestBody: MtmUserCredentialForceResetForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admins/user-credentials/force/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할 조회
 * @returns MtmLissResponseRolePermissionDto OK
 * @throws ApiError
 */
export function getRole(
    {
        roleId,
    }: {
        roleId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseRolePermissionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/roles/{roleId}/v1',
                path: {
                    roleId: roleId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할 수정
 * @returns MtmLissResponseRoleCreatedDto OK
 * @throws ApiError
 */
export function updateRole(
    {
        roleId,
        requestBody,
    }: {
        roleId: number;
        requestBody: MtmRoleUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseRoleCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/roles/{roleId}/v1',
                path: {
                    roleId: roleId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할 삭제
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deleteRole(
    {
        roleId,
    }: {
        roleId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/roles/{roleId}/v1',
                path: {
                    roleId: roleId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할별 설정 가능한 퍼미션 목록 조회
 * @returns MtmLissResponseListPermissionCategoryDto OK
 * @throws ApiError
 */
export function getPermissions(
    {
        roleId,
    }: {
        roleId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseListPermissionCategoryDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/roles/{roleId}/permissions/v1',
                path: {
                    roleId: roleId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할 - 퍼미션 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updatePermission(
    {
        roleId,
        requestBody,
    }: {
        roleId: number;
        requestBody: MtmRolePermissionForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/roles/{roleId}/permissions/v1',
                path: {
                    roleId: roleId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할 - 퍼미션 추가
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function addPermissions(
    {
        roleId,
        requestBody,
    }: {
        roleId: number;
        requestBody: MtmRolePermissionForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/roles/{roleId}/permissions/v1',
                path: {
                    roleId: roleId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할 - 퍼미션 삭제
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deletePermission(
    {
        roleId,
        requestBody,
    }: {
        roleId: number;
        requestBody: MtmRolePermissionForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/roles/{roleId}/permissions/v1',
                path: {
                    roleId: roleId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 퍼미션 조회
 * @returns MtmLissResponsePermissionDto OK
 * @throws ApiError
 */
export function getPermission(
    {
        id,
    }: {
        id: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePermissionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/permissions/{id}/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 퍼미션 수정
 * @returns MtmLissResponsePermissionCreatedDto OK
 * @throws ApiError
 */
export function updatePermission1(
    {
        id,
        requestBody,
    }: {
        id: number;
        requestBody: MtmPermissionUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePermissionCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/permissions/{id}/v1',
                path: {
                    id: id,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 퍼미션 삭제
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deletePermission1(
    {
        id,
    }: {
        id: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/permissions/{id}/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 공지사항 상세 조회
 * @returns MtmLissResponseNoticeDto OK
 * @throws ApiError
 */
export function getNotice(
    {
        noticeId,
    }: {
        noticeId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseNoticeDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/notices/{noticeId}/v1',
                path: {
                    noticeId: noticeId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 공지사항 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateNotice(
    {
        noticeId,
        requestBody,
    }: {
        noticeId: number;
        requestBody: MtmNoticeUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/notices/{noticeId}/v1',
                path: {
                    noticeId: noticeId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 공지사항 삭제
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deleteNotice(
    {
        noticeId,
    }: {
        noticeId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/notices/{noticeId}/v1',
                path: {
                    noticeId: noticeId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 내 유저 라이센스 할당 리스트 조회
 * @returns MtmLissResponseListUserWorkspaceLicenseDto OK
 * @throws ApiError
 */
export function listUsersWithLicense(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListUserWorkspaceLicenseDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/license/users/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 내 유저 라이센스 할당 리스트 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateUsersLicense(
    {
        requestBody,
    }: {
        requestBody: MtmUsersLicenseUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/license/users/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 직책 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateJobTitle(
    {
        jobTitleId,
        requestBody,
    }: {
        jobTitleId: number;
        requestBody: MtmJobTitleUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/job-titles/{jobTitleId}/v1',
                path: {
                    jobTitleId: jobTitleId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 직책 삭제
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deleteJobTitle(
    {
        jobTitleId,
    }: {
        jobTitleId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/job-titles/{jobTitleId}/v1',
                path: {
                    jobTitleId: jobTitleId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 직책 순서 벌크 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateJobTitlesDisplayOrder(
    {
        requestBody,
    }: {
        requestBody: MtmUpdateDisplayOrderForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/job-titles/display-order/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 직위 수정
 * @returns MtmLissResponseJobPositionDto OK
 * @throws ApiError
 */
export function updateJobPosition(
    {
        jobPositionId,
        requestBody,
    }: {
        jobPositionId: number;
        requestBody: MtmJobPositionUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseJobPositionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/job-positions/{jobPositionId}/v1',
                path: {
                    jobPositionId: jobPositionId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 직위 삭제
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deleteJobPosition(
    {
        jobPositionId,
    }: {
        jobPositionId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/job-positions/{jobPositionId}/v1',
                path: {
                    jobPositionId: jobPositionId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 직위 순서 벌크 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateJobPositionsDisplayOrder(
    {
        requestBody,
    }: {
        requestBody: MtmUpdateDisplayOrderForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/job-positions/display-order/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 그룹 조회
 * @returns MtmLissResponseGroupDto OK
 * @throws ApiError
 */
export function getGroup(
    {
        groupId,
    }: {
        groupId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseGroupDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/groups/{groupId}/v1',
                path: {
                    groupId: groupId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 그룹 수정
 * @returns MtmLissResponseGroupDto OK
 * @throws ApiError
 */
export function updateGroup(
    {
        groupId,
        requestBody,
    }: {
        groupId: number;
        requestBody: MtmGroupUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseGroupDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/groups/{groupId}/v1',
                path: {
                    groupId: groupId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 그룹 삭제
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deleteGroup(
    {
        groupId,
    }: {
        groupId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/groups/{groupId}/v1',
                path: {
                    groupId: groupId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 그룹 사용자 목록 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function getGroupUsers(
    {
        groupId,
    }: {
        groupId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/groups/{groupId}/users/v1',
                path: {
                    groupId: groupId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 그룹 사용자 벌크 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateGroupUsers(
    {
        groupId,
        requestBody,
    }: {
        groupId: number;
        requestBody: MtmGroupUserForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/groups/{groupId}/users/v1',
                path: {
                    groupId: groupId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 조직도 조회
 * @returns MtmLissResponseListDivisionNodeDto OK
 * @throws ApiError
 */
export function getDivisionTree(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListDivisionNodeDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/divisions/tree/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 조직도 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateDivisionTree(
    {
        requestBody,
    }: {
        requestBody: MtmDivisionNodesForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/divisions/tree/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 결제수단 변경
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateBillingMethod(
    {
        billingMethodId,
        requestBody,
    }: {
        billingMethodId: number;
        requestBody: MtmBillingMethodRegisterForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/billing-method/{billingMethodId}/v1',
                path: {
                    billingMethodId: billingMethodId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 결제수단 삭제
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deleteBillingMethod(
    {
        billingMethodId,
    }: {
        billingMethodId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/billing-method/{billingMethodId}/v1',
                path: {
                    billingMethodId: billingMethodId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 온보딩 (워크스페이스 생성 및 사용자 할당) V2
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function onboardWorkspaceV2(
    {
        requestBody,
    }: {
        requestBody: MtmWorkspaceOnboardingCreateFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/workspace/onboardings/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 워크스페이스 온보딩 (워크스페이스 생성 및 사용자 할당)
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function onboardWorkspace(
    {
        requestBody,
    }: {
        requestBody: MtmWorkspaceOnboardingCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/workspace/onboardings/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 삭제 요청하기 V1
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function requestDeletion(
    {
        requestBody,
    }: {
        requestBody: MtmWorkspaceDeletionRequestForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/workspace-deletion-requests/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * OTP 검증하기 V1
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function verifyOtpV1(
    {
        xVerificationToken,
        requestBody,
    }: {
        xVerificationToken: string;
        requestBody: MtmUserVerificationVerifyOtpForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/user/verifications/otp/verify/v1',
                headers: {
                    'X-Verification-Token': xVerificationToken,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * OTP 발송하기 V1
 * @returns MtmLissResponseUserVerificationDto OK
 * @throws ApiError
 */
export function sendOtpV1(
    {
        requestBody,
    }: {
        requestBody: MtmUserVerificationSendOtpForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseUserVerificationDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/user/verifications/otp/send/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 초대 v2 (단일 역할)
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function inviteUser(
    {
        requestBody,
    }: {
        requestBody: MtmUserInvitationFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/user-invitations/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 초대 목록 조회
 * @returns MtmLissResponsePageUserInvitationOverviewDto OK
 * @throws ApiError
 */
export function pageInvitations(
    {
        groupId,
        loginId,
        expired,
        jobPositionIds,
        statuses,
        page,
        size = 30,
        sort,
    }: {
        groupId?: number;
        loginId?: string;
        expired?: boolean;
        jobPositionIds?: Array<number>;
        statuses?: Array<'PENDING' | 'SENT' | 'ACCEPTED' | 'CANCELLED' | 'EXPIRED' | 'FAILED'>;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePageUserInvitationOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/user-invitations/v1',
                query: {
                    groupId: groupId,
                    loginId: loginId,
                    expired: expired,
                    jobPositionIds: jobPositionIds,
                    statuses: statuses,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 사용자 초대
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function inviteUser1(
    {
        requestBody,
    }: {
        requestBody: MtmUserInvitationForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/user-invitations/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 초대 재발송
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function resendInvitations(
    {
        requestBody,
    }: {
        requestBody: MtmUserInvitationResendForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/user-invitations/resend/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 약관 동의
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function consentTermRevisions(
    {
        requestBody,
    }: {
        requestBody: MtmTermRevisionConsentForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/terms/revisions/consent/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 정기결제 약관 추가 동의
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function consentRepeatOrderTermRevisions(
    {
        requestBody,
    }: {
        requestBody: MtmTermRevisionConsentForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/terms/revisions/consent/repeat-order/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 엑셀로 사용자 생성 API
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function createUsersByExcel(
    {
        formData,
    }: {
        formData?: {
            file: Blob;
        };
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admins/users/excel/v1',
                formData: formData,
                mediaType: 'multipart/form-data',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 생성 API
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function createUsersV2(
    {
        requestBody,
    }: {
        requestBody: MtmSuperAdminUserCreateFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admins/user/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 슈퍼어드민 사용자 검색 API
 * @returns MtmLissResponsePageSuperAdminUserOverviewVo OK
 * @throws ApiError
 */
export function pageUsers1(
    {
        searchKeyword,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 검색어
         */
        searchKeyword?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePageSuperAdminUserOverviewVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admins/user/v1',
                query: {
                    searchKeyword: searchKeyword,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 사용자 생성 API
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function createUsers(
    {
        requestBody,
    }: {
        requestBody: MtmSuperAdminUserCreateFormV1;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admins/user/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 회원가입 - 계정 생성
 * @returns MtmLissResponseUserCreatedDto OK
 * @throws ApiError
 */
export function createUser(
    {
        requestBody,
    }: {
        requestBody: MtmUserCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseUserCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/sign-up/users/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 회원가입 - 워크스페이스 추가
 * @returns MtmLissResponseUserWorkspaceCreatedDto OK
 * @throws ApiError
 */
export function createUserWorkspace(
    {
        requestBody,
    }: {
        requestBody: MtmUserWorkspaceCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseUserWorkspaceCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/sign-up/user-workspaces/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할 생성 v2
 * @returns MtmLissResponseRoleCreatedDto OK
 * @throws ApiError
 */
export function createRole(
    {
        requestBody,
    }: {
        requestBody: MtmRoleCreateFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseRoleCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/roles/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할 목록 조회 - 제품별
 * @returns MtmLissResponseListProductWithRoleDto OK
 * @throws ApiError
 */
export function listRoles(
    {
        type,
    }: {
        type?: 'GUEST' | 'USER';
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseListProductWithRoleDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/roles/v1',
                query: {
                    type: type,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 역할 생성
 * @returns MtmLissResponseRoleCreatedDto OK
 * @throws ApiError
 */
export function createRole1(
    {
        requestBody,
    }: {
        requestBody: MtmRoleCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseRoleCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/roles/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 요청 목록 조회
 * @returns MtmLissResponsePageRoleRequestDto OK
 * @throws ApiError
 */
export function pageUserRequests(
    {
        statuses,
        requesterIds,
        roleIds,
        approveUserId,
        page,
        size = 30,
        sort,
    }: {
        statuses?: Array<'REQUESTED' | 'APPROVED' | 'REJECTED'>;
        requesterIds?: Array<number>;
        roleIds?: Array<number>;
        approveUserId?: number;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePageRoleRequestDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/role-requests/users/v1',
                query: {
                    statuses: statuses,
                    requesterIds: requesterIds,
                    roleIds: roleIds,
                    approveUserId: approveUserId,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 요청 추가
 * @returns MtmLissResponseListUserRoleRequestCreatedDto OK
 * @throws ApiError
 */
export function createUserRequest(
    {
        requestBody,
    }: {
        requestBody: MtmUserRoleRequestCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseListUserRoleRequestCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/role-requests/users/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 요청 거부
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function rejectUserRequest(
    {
        requestBody,
    }: {
        requestBody: MtmRoleRequestStateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/role-requests/users/rejected/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 요청 승인
 * @returns MtmLissResponseListApprovedRoleRequestDto OK
 * @throws ApiError
 */
export function approveUserRequest(
    {
        requestBody,
    }: {
        requestBody: MtmRoleRequestStateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseListApprovedRoleRequestDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/role-requests/users/approved/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 요청 목록 조회
 * @returns MtmLissResponsePageRoleRequestDto OK
 * @throws ApiError
 */
export function pageGroupRequests(
    {
        statuses,
        requesterIds,
        roleIds,
        approveUserId,
        page,
        size = 30,
        sort,
    }: {
        statuses?: Array<'REQUESTED' | 'APPROVED' | 'REJECTED'>;
        requesterIds?: Array<number>;
        roleIds?: Array<number>;
        approveUserId?: number;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePageRoleRequestDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/role-requests/groups/v1',
                query: {
                    statuses: statuses,
                    requesterIds: requesterIds,
                    roleIds: roleIds,
                    approveUserId: approveUserId,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 요청 추가
 * @returns MtmLissResponseListGroupRoleRequestCreatedDto OK
 * @throws ApiError
 */
export function createGroupRequest(
    {
        requestBody,
    }: {
        requestBody: MtmGroupRoleRequestCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseListGroupRoleRequestCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/role-requests/groups/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 요청 거부
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function rejectGroupRequest(
    {
        requestBody,
    }: {
        requestBody: MtmRoleRequestStateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/role-requests/groups/rejected/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 요청 승인
 * @returns MtmLissResponseListApprovedRoleRequestDto OK
 * @throws ApiError
 */
export function approveGroupRequest(
    {
        requestBody,
    }: {
        requestBody: MtmRoleRequestStateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseListApprovedRoleRequestDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/role-requests/groups/approved/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 활성 퍼미션 리스트 조회 (구독 제품 기준)
 * @returns MtmLissResponseListPermissionDto OK
 * @throws ApiError
 */
export function listPermissions(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListPermissionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/permissions/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 퍼미션 생성
 * @returns MtmLissResponsePermissionCreatedDto OK
 * @throws ApiError
 */
export function createPermission(
    {
        requestBody,
    }: {
        requestBody: MtmPermissionCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePermissionCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/permissions/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 주문 발급 v2
 * @returns MtmLissResponseOrderDto OK
 * @throws ApiError
 */
export function publishOrderV2(
    {
        requestBody,
    }: {
        requestBody: MtmOrderPublishFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseOrderDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/orders/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 주문 발급
 * @returns MtmLissResponseOrderDto OK
 * @throws ApiError
 */
export function publishOrder(
    {
        requestBody,
    }: {
        requestBody: MtmOrderPublishForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseOrderDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/orders/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 정기 주문(결제) 등록
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function registerRepeatOrder(
    {
        requestBody,
    }: {
        requestBody: MtmRepeatOrderRegisterForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/orders/repeat/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 정기 주문(결제) 해지 취소
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function cancelRepeatOrderExpiration(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/orders/repeat/reactivate/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 정기 주문(결제) 해지
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function expireRepeatOrder(
    {
        requestBody,
    }: {
        requestBody: MtmRepeatOrderDeactivateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/orders/repeat/deactivate/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 플랜 업그레이드 및 추가 라이선스 주문 및 결제
 * @returns MtmLissResponseOrderDto OK
 * @throws ApiError
 */
export function adjustPlan(
    {
        requestBody,
    }: {
        requestBody: MtmPlanAdjustmentForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseOrderDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/orders/plans/adjustments/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function onboardBasicInfoAndTerm(
    {
        xVerificationToken,
        requestBody,
    }: {
        xVerificationToken: string;
        requestBody: MtmOnboardingBasicInfoTermForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/onboardings/step/BASIC_INFO_TERM/v1',
                headers: {
                    'X-Verification-Token': xVerificationToken,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 공지사항 생성
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function createNotice(
    {
        requestBody,
    }: {
        requestBody: MtmNoticeCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/notices/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 공지사항 파일 업로드 API
 * @returns MtmLissResponseArxiveFileRevisionDto OK
 * @throws ApiError
 */
export function uploadNoticeFile(
    {
        formData,
    }: {
        formData?: {
            file: Blob;
        };
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseArxiveFileRevisionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/notices/files/upload/v1',
                formData: formData,
                mediaType: 'multipart/form-data',
            }),
            requestConfig,
        ),
    );
}
/**
 * 직책 벌크 생성 API (직책 코드 포함)
 * @returns MtmLissResponseListJobTitleDto OK
 * @throws ApiError
 */
export function createJobTitles(
    {
        requestBody,
    }: {
        requestBody: Array<MtmJobTitleCreateForm>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseListJobTitleDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/job-titles/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 내 모든 직책 조회
 * @returns MtmLissResponseListJobTitleDto OK
 * @throws ApiError
 */
export function getJobTitles(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListJobTitleDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/job-titles/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 직책 추가 API
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function addJobTitles(
    {
        requestBody,
    }: {
        requestBody: MtmJobTitleAddForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/job-titles/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 직위 벌크 추가 v2
 * @returns MtmLissResponseListJobPositionDto OK
 * @throws ApiError
 */
export function createJobPositionV2(
    {
        requestBody,
    }: {
        requestBody: Array<MtmJobPositionCreateForm>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseListJobPositionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/job-positions/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 직위 리스트 조회
 * @returns MtmLissResponseListJobPositionDto OK
 * @throws ApiError
 */
export function getJobPosition(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListJobPositionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/job-positions/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 직위 벌크 추가
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function createJobPosition(
    {
        requestBody,
    }: {
        requestBody: MtmJobPositionAddForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/job-positions/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 그룹 역할 목록 조회
 * @returns MtmLissResponseListRoleDto OK
 * @throws ApiError
 */
export function getGroupRoles(
    {
        groupId,
    }: {
        groupId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseListRoleDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/groups/{groupId}/roles/v1',
                path: {
                    groupId: groupId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 그룹 역할 벌크 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function addGroupRoles(
    {
        groupId,
        requestBody,
    }: {
        groupId: number;
        requestBody: MtmGroupRoleForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/groups/{groupId}/roles/v1',
                path: {
                    groupId: groupId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 그룹 역할 벌크 삭제
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deleteGroupRoles(
    {
        groupId,
        requestBody,
    }: {
        groupId: number;
        requestBody: MtmGroupRoleForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/groups/{groupId}/roles/v1',
                path: {
                    groupId: groupId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 그룹 목록 조회
 * @returns MtmLissResponsePageGroupDto OK
 * @throws ApiError
 */
export function pageGroups(
    {
        parentId,
        name,
        type,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 부모 그룹 ID
         */
        parentId?: number;
        /**
         * 그룹명
         */
        name?: string;
        /**
         * 그룹 타입
         */
        type?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePageGroupDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/groups/v1',
                query: {
                    parentId: parentId,
                    name: name,
                    type: type,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 그룹 생성
 * @returns MtmLissResponseGroupDto OK
 * @throws ApiError
 */
export function createGroup(
    {
        requestBody,
    }: {
        requestBody: MtmGroupCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseGroupDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/groups/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function sendMail(
    {
        requestBody,
    }: {
        requestBody: MtmExternalMailForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/external/mails/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 부서 생성
 * @returns MtmLissResponseGroupDto OK
 * @throws ApiError
 */
export function createDivision(
    {
        requestBody,
    }: {
        requestBody: MtmDivisionCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseGroupDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/divisions/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 크레딧 주문(충전)
 * @returns MtmLissResponseCreditOrderDto OK
 * @throws ApiError
 */
export function orderCredits(
    {
        requestBody,
    }: {
        requestBody: MtmCreditOrderForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseCreditOrderDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/credits/orders/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 결제수단 등록
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function registerBillingMethod(
    {
        requestBody,
    }: {
        requestBody: MtmBillingMethodRegisterForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/billing-method/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 단건 조회
 * @returns MtmLissResponseWorkspaceDto OK
 * @throws ApiError
 */
export function getWorkspace(
    {
        id,
    }: {
        id: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseWorkspaceDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workspaces/{id}/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 유저 총원 조회
 * @returns MtmLissResponseLong OK
 * @throws ApiError
 */
export function getWorkspaceUsersCount(
    {
        id,
    }: {
        id: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseLong> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workspaces/{id}/user-count/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 메타데이터 v1
 * @returns MtmLissResponseMapStringObject OK
 * @throws ApiError
 */
export function getWorkspaceMeta(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseMapStringObject> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workspaces/meta/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 현재 워크스페이스 정보 조회
 * @returns MtmLissResponseWorkspaceDto OK
 * @throws ApiError
 */
export function getCurrentWorkspace(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseWorkspaceDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workspaces/current/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 현재 워크스페이스 상세 정보 조회
 * @returns MtmLissResponseWorkspaceDetailInfoDto OK
 * @throws ApiError
 */
export function getCurrentWorkspaceDetailV2(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseWorkspaceDetailInfoDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workspaces/current/detail/v2',
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 현재 워크스페이스 상세 정보 조회
 * @returns MtmLissResponseWorkspaceDetailInfoVo OK
 * @throws ApiError
 */
export function getCurrentWorkspaceDetail(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseWorkspaceDetailInfoVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workspaces/current/detail/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 삭제 요청 정보 조회하기 V1
 * @returns MtmLissResponseWorkspaceDeletionRequestDto OK
 * @throws ApiError
 */
export function getCurrentWorkspaceDeletionRequest(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseWorkspaceDeletionRequestDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workspace-deletion-requests/current/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 삭제 요청 철회하기 V1
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function withdrawCurrentWorkspaceDeletionRequest(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/workspace-deletion-requests/current/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 정보 조회하기 V1
 * @returns MtmLissResponseWorkspaceForDeletionDto OK
 * @throws ApiError
 */
export function getCurrentWorkspaceForDeletion(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseWorkspaceForDeletionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workspace-deletion-requests/current-workspace/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 유저가 설정 가능한 목록 조회
 * @returns MtmLissResponseListWorkspaceConfigurationDto OK
 * @throws ApiError
 */
export function listUserManagedWorkspaceConfigurations(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListWorkspaceConfigurationDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workspace-configurations/managed/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 설정 조회
 * @returns MtmLissResponseWorkspaceConfigDto OK
 * @throws ApiError
 */
export function getCurrentWorkspaceConfig(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseWorkspaceConfigDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/workspace-configs/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 조회(overview)
 * @returns MtmLissResponseUserOverviewDto OK
 * @throws ApiError
 */
export function getUserOverview(
    {
        userId,
    }: {
        userId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/{userId}/overview/v1',
                path: {
                    userId: userId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 목록 페이징 조회 v2(어드민)
 * @returns MtmLissResponsePageUserDtoV2 OK
 * @throws ApiError
 */
export function pageUsersV2(
    {
        keyword,
        page,
        size = 10,
        sort,
    }: {
        keyword?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePageUserDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/v2',
                query: {
                    keyword: keyword,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 목록 페이징 조회(어드민)
 * @returns MtmLissResponsePageUserDto OK
 * @throws ApiError
 */
export function pageUsers(
    {
        keyword,
        page,
        size = 30,
        sort,
        external,
    }: {
        keyword?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
        external?: boolean;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePageUserDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/v1',
                query: {
                    keyword: keyword,
                    page: page,
                    size: size,
                    sort: sort,
                    external: external,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * CLM 의뢰 결재자 조회
 * @returns MtmLissResponseListUserSimpleAffiliationDto OK
 * @throws ApiError
 */
export function listReviewApprovers(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListUserSimpleAffiliationDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/review-approvers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 목록 페이징 조회(overview)
 * @returns MtmLissResponsePageUserOverviewDto OK
 * @throws ApiError
 */
export function pageUserOverviews(
    {
        searchKeyword,
        page,
        size = 30,
        sort,
    }: {
        /**
         * 검색 키워드
         */
        searchKeyword?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePageUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/overview/v1',
                query: {
                    searchKeyword: searchKeyword,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * ELM 송무 열람자 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listLitigationReaders(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/litigation-readers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * CLM 법무 담당자 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listLegalReviewers(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/legal-reviewers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * CLM 법무 배정자 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listLegalAssigners(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/legal-assigners/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * CLM 계약서 열람자 조회 (소속 부서 내 계약)
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listGroupContractReaders(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/group-contract-readers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * ELM 송무 COMPLETE 권한 가진 사용자 목록 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listElmLitigationOwners(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/elm-litigation-completes/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * ELM 법무 검토자 목록 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listElmLegalReviewers(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/elm-legal-reviewers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * ELM 법무 검토승인자 목록 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listElmLegalApprovers(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/elm-legal-approvers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * ELM 외부 변호사 권한 가진 사용자 목록 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listElmExternalLawyers(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/elm-external-lawyers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * CLM 계약서 열람자 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listContractReaders(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/contract-readers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * ELM 자문 열람자 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listConsultingReaders(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/consulting-readers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 화면에서 사용하는 코드 조회
 * @returns MtmLissResponseMapStringListObject OK
 * @throws ApiError
 */
export function getUserCodes(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseMapStringListObject> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/codes/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할 사용자 목록 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listUsersByRole(
    {
        roleId,
    }: {
        roleId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/by-role/{roleId}/v1',
                path: {
                    roleId: roleId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * CLM 계약서 열람자 조회 (배정 계약)
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listAssignedContractReaders(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/assigned-contract-readers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * CLM 할당 전자결재 열람자 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listAssignedApprovalReaders(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/assigned-approval-readers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 목록 조회
 * @returns MtmLissResponseListUserOverviewDto OK
 * @throws ApiError
 */
export function listUsers(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListUserOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/users/all/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 초대 조회
 * @returns MtmLissResponseUserInvitationDto OK
 * @throws ApiError
 */
export function getInvitationByToken(
    {
        token,
    }: {
        token: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseUserInvitationDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/user-invitations/invitation/v1',
                query: {
                    token: token,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 기가입자 여부 확인
 * @returns MtmLissResponseBoolean OK
 * @throws ApiError
 */
export function checkUserExists(
    {
        loginId,
    }: {
        loginId: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseBoolean> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/user-invitations/check-user-exists/v1',
                query: {
                    loginId: loginId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 약관 파일 다운로드
 * @returns binary OK
 * @throws ApiError
 */
export function downloadTermRevision(
    {
        termId,
        revisionId,
    }: {
        termId: number;
        revisionId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<Blob> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/terms/{termId}/revisions/{revisionId}/download/v1',
                path: {
                    termId: termId,
                    revisionId: revisionId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 약관 리비전 리스트 조회
 * @returns MtmLissResponseListTermRevisionOverviewDto OK
 * @throws ApiError
 */
export function listRevisionOverviews(
    {
        id,
    }: {
        id: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseListTermRevisionOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/terms/{id}/revisions/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 발효중인 약관 리스트 조회
 * @returns MtmLissResponseListTermSimpleDto OK
 * @throws ApiError
 */
export function listTermsActiveAndEffective(
    {
        type,
    }: {
        /**
         * 약관 유형
         */
        type?: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseListTermSimpleDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/terms/v1',
                query: {
                    type: type,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 미동의 서비스 약관 리스트 조회
 * @returns MtmLissResponseListTermSimpleDto OK
 * @throws ApiError
 */
export function getUnConsentedTerms(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListTermSimpleDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/terms/un-consented/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 미동의 정기결제 약관 리스트 조회
 * @returns MtmLissResponseListTermSimpleDto OK
 * @throws ApiError
 */
export function getUnConsentedRepeatOrderTerms(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListTermSimpleDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/terms/un-consented/repeat-order/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 슈퍼 어드민 목록 조회
 * @returns MtmLissResponsePageSuperAdminVo OK
 * @throws ApiError
 */
export function listSuperAdmins(
    {
        page,
        size = 20,
        sort,
    }: {
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePageSuperAdminVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admins/v1',
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 현재 구독 정보 조회
 * @returns MtmLissResponseSubscriptionInfoDto OK
 * @throws ApiError
 */
export function getCurrentSubscription(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseSubscriptionInfoDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/subscriptions/current/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할 목록 조회 - 사용자 수 포함
 * @returns MtmLissResponseListRoleWithUserCountDto OK
 * @throws ApiError
 */
export function getRolesWithUserCounts(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListRoleWithUserCountDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/roles/user-count/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할 메타데이터 v1
 * @returns MtmLissResponseMapStringObject OK
 * @throws ApiError
 */
export function getRoleMeta(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseMapStringObject> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/roles/meta/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 역할 승인 가능 권한자 목록 조회
 * @returns MtmLissResponseListUserIdentityDto OK
 * @throws ApiError
 */
export function getApproveUsers(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListUserIdentityDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/role-requests/approvers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 활성 제품 목록 조회
 * @returns MtmLissResponseListProductDto OK
 * @throws ApiError
 */
export function getProducts(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListProductDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/products/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 주문 가능 플랜 및 요금제 조회 V3
 * @returns MtmLissResponseListPlanPricesDtoV3 OK
 * @throws ApiError
 */
export function listRegularPlanPricesV3(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListPlanPricesDtoV3> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/plans/prices/v3',
            }),
            requestConfig,
        ),
    );
}
/**
 * 주문 가능 플랜 별 정가 요금제 조회
 * @returns MtmLissResponseListPlanPricesDtoV2 OK
 * @throws ApiError
 */
export function listRegularPlanOriginalPrices(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListPlanPricesDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/plans/prices/originals/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 플랜 업그레이드 및 추가 라이선스 금액 조회 (일단위 가격)
 * @returns MtmLissResponseLicenseDailyPricesDto OK
 * @throws ApiError
 */
export function listLicensePrices(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseLicenseDailyPricesDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/plans/prices/daily-license/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 플랜 별 기본 요금제 조회
 * @returns MtmLissResponseListPlanPricesDtoV3 OK
 * @throws ApiError
 */
export function listBasePlanPrices(
    {
        planType,
    }: {
        planType: 'BASIC' | 'STANDARD' | 'ENTERPRISE' | 'TRIAL' | 'REGULAR' | 'CUSTOM';
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseListPlanPricesDtoV3> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/plans/prices/base/v1',
                query: {
                    planType: planType,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 주문 상태 조회
 * @returns MtmLissResponseOrderStatusType OK
 * @throws ApiError
 */
export function getOrder(
    {
        orderRequestId,
    }: {
        orderRequestId: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseOrderStatusType> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/orders/{orderRequestId}/status/v1',
                path: {
                    orderRequestId: orderRequestId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 정기 주문(결제) 해지 사유 Enum값 조회
 * @returns MtmLissResponseMapStringString OK
 * @throws ApiError
 */
export function getRepeatOrderExpirationReasons(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseMapStringString> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/orders/repeat/deactivate-reasons/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 플랜 주문 및 결제 가능 여부 확인
 * @returns MtmLissResponseOrderAvailabilityDto OK
 * @throws ApiError
 */
export function getOrderAvailability(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseOrderAvailabilityDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/orders/plans/availability/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 주문 메타데이터 v1
 * @returns MtmLissResponseMapStringObject OK
 * @throws ApiError
 */
export function getOrderTypesMeta(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseMapStringObject> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/orders/meta/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 주문 이력 조회
 * @returns MtmLissResponsePageOrderHistoryDto OK
 * @throws ApiError
 */
export function getOrderHistories(
    {
        pageable,
    }: {
        pageable: MtmPageable;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePageOrderHistoryDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/orders/histories/v1',
                query: {
                    pageable: pageable,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns MtmLissResponseOnboardingProcessDto OK
 * @throws ApiError
 */
export function getProcessWithSteps(
    {
        processType,
    }: {
        processType: 'SELF';
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseOnboardingProcessDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/onboardings/process/v1',
                query: {
                    processType: processType,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns MtmLissResponseListConfigurationDto OK
 * @throws ApiError
 */
export function getOnboardingConfigurations(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListConfigurationDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/onboardings/configurations/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * @returns MtmLissResponseBoolean OK
 * @throws ApiError
 */
export function checkLoginIdAvailable(
    {
        loginId,
    }: {
        loginId: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseBoolean> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/onboardings/check-id-available/v1',
                query: {
                    loginId: loginId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 공지사항 파일 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadFile(
    {
        noticeId,
        fileKey,
    }: {
        noticeId: number;
        /**
         * 파일 Key
         */
        fileKey: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/notices/{noticeId}/download/v1',
                path: {
                    noticeId: noticeId,
                },
                query: {
                    fileKey: fileKey,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 공지사항 검색
 * @returns MtmLissResponsePageNoticeVo OK
 * @throws ApiError
 */
export function searchNotice(
    {
        createdDateStart,
        createdDateEnd,
        keyword,
        noticeTypes,
        creatorIds,
        page,
        size = 10,
        sort,
    }: {
        /**
         * 등록일(시작)
         */
        createdDateStart: string;
        /**
         * 등록일(종료)
         */
        createdDateEnd: string;
        /**
         * 검색어
         */
        keyword?: string;
        /**
         * 공지 사항 구분
         */
        noticeTypes?: Array<'UNSPECIFIED' | 'CONTRACT' | 'LITIGATION' | 'CONSULTING'>;
        /**
         * 공지 사항 작성자 목록
         */
        creatorIds?: Array<number>;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePageNoticeVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/notices/search/v1',
                query: {
                    keyword: keyword,
                    createdDateStart: createdDateStart,
                    createdDateEnd: createdDateEnd,
                    noticeTypes: noticeTypes,
                    creatorIds: creatorIds,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 공지사항 작성자 목록 조회
 * @returns MtmLissResponseListUserProfileSimpleVo OK
 * @throws ApiError
 */
export function getCreators(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListUserProfileSimpleVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/notices/creators/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 공지사항 화면에서 사용하는 코드 조회
 * @returns MtmLissResponseMapStringListObject OK
 * @throws ApiError
 */
export function getNoticeCodes(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseMapStringListObject> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/notices/codes/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 내 소속 워크스페이스 리스트 조회
 * @returns MtmLissResponseListMyWorkspaceDto OK
 * @throws ApiError
 */
export function getMyWorkspaces(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListMyWorkspaceDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/my-info/workspaces/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 내 워크스페이스 정보 조회
 * @returns MtmLissResponseMyWorkspaceInfoDto OK
 * @throws ApiError
 */
export function getMyWorkspaceInfo(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseMyWorkspaceInfoDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/my-info/workspace/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 내 정보 조회
 * @returns MtmLissResponseMyInfoDto OK
 * @throws ApiError
 */
export function getMyInfo(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseMyInfoDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/my-info/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 내 역할 조회
 * @returns MtmLissResponseListRoleDto OK
 * @throws ApiError
 */
export function getMyRoles(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListRoleDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/my-info/roles/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 내 그룹 조회
 * @returns MtmLissResponseListGroupDto OK
 * @throws ApiError
 */
export function getMyGroups(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListGroupDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/my-info/groups/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 내 워크스페이스내의 소속 정보 조회
 * @returns MtmLissResponseMyAffiliationDto OK
 * @throws ApiError
 */
export function getMyAffiliation(
    {
        workspaceId,
    }: {
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseMyAffiliationDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/my-info/affiliation/{workspaceId}/v1',
                path: {
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 라이선스 수 및 할당 유저 수 조회 API
 * @returns MtmLissResponseLicenseUserCountDto OK
 * @throws ApiError
 */
export function countLicenseAndUsers(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseLicenseUserCountDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/license/user-count/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 런처 조회 v2
 * @returns MtmLissResponseListLauncherDtoV2 OK
 * @throws ApiError
 */
export function getLauncherV2(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListLauncherDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/launchers/v2',
            }),
            requestConfig,
        ),
    );
}
/**
 * 런처 조회
 * @returns MtmLissResponseListLauncherDto OK
 * @throws ApiError
 */
export function getLauncher(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListLauncherDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/launchers/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 부서 상세 조회
 * @returns MtmLissResponseDivisionDto OK
 * @throws ApiError
 */
export function getDivision(
    {
        divisionId,
    }: {
        divisionId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseDivisionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/divisions/{divisionId}/v1',
                path: {
                    divisionId: divisionId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 부서 삭제
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deleteDivision(
    {
        divisionId,
    }: {
        divisionId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/divisions/{divisionId}/v1',
                path: {
                    divisionId: divisionId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 부서 내 사용자 조회 (소속 정보 포함)
 * @returns MtmLissResponseDivisionMembersDto OK
 * @throws ApiError
 */
export function getDivisionUsers(
    {
        divisionId,
    }: {
        divisionId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseDivisionMembersDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/divisions/{divisionId}/users/v1',
                path: {
                    divisionId: divisionId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 크레딧 사용량 메타데이터 v1
 * @returns MtmLissResponseMapStringObject OK
 * @throws ApiError
 */
export function getCreditStatisticsMeta(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseMapStringObject> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/credits/statistics/meta/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자별 크레딧 사용량 통계 조회 v1
 * @returns MtmLissResponsePageCreditStatisticByUserDto OK
 * @throws ApiError
 */
export function pageCreditStatisticsByUsers(
    {
        yearMonth,
        page,
        size = 30,
        sort,
    }: {
        /**
         * 조회 대상 년월 (yyyy-MM)
         */
        yearMonth?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePageCreditStatisticByUserDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/credits/statistics/by-user/v1',
                query: {
                    yearMonth: yearMonth,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 서비스별 크레딧 사용량 통계 조회 v1
 * @returns MtmLissResponseListCreditStatisticsByServiceDto OK
 * @throws ApiError
 */
export function pageCreditStatisticsByServices(
    {
        yearMonth,
    }: {
        /**
         * 조회 대상 년월 (yyyy-MM)
         */
        yearMonth?: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseListCreditStatisticsByServiceDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/credits/statistics/by-service/v1',
                query: {
                    yearMonth: yearMonth,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 크레딧 가격 목록 조회
 * @returns MtmLissResponseListOrderableCreditPriceDto OK
 * @throws ApiError
 */
export function listOrderableCreditPrices(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListOrderableCreditPriceDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/credits/orders/prices/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 크레딧 주문 이력 조회
 * @returns MtmLissResponsePageCreditOrderHistoryDto OK
 * @throws ApiError
 */
export function pageCreditOrderHistories(
    {
        pageable,
    }: {
        pageable: MtmPageable;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePageCreditOrderHistoryDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/credits/orders/histories/v1',
                query: {
                    pageable: pageable,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 이번달 사용 크레딧 조회
 * @returns MtmLissResponseCreditsDto OK
 * @throws ApiError
 */
export function getMonthlyConsumedCredits(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseCreditsDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/credits/monthly-consumed/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 크레딧 관련 Metadata 조회
 * @returns MtmLissResponseMapStringObject OK
 * @throws ApiError
 */
export function listCreditMetadata(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseMapStringObject> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/credits/meta/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 크레딧 잔액 조회
 * @returns MtmLissResponseCreditBalanceDto OK
 * @throws ApiError
 */
export function getCreditBalance(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseCreditBalanceDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/credits/balance/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 설정 목록 조회
 * @returns MtmLissResponseListConfigurationDto OK
 * @throws ApiError
 */
export function listConfigurations(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListConfigurationDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/configurations/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 삭제
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deleteUser(
    {
        id,
    }: {
        id: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/users/{id}/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 초대 취소
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function cancelInvitation(
    {
        invitationId,
    }: {
        invitationId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/user-invitations/{invitationId}/v1',
                path: {
                    invitationId: invitationId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 삭제 API
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deleteUser1(
    {
        userId,
    }: {
        userId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/super-admins/user/{userId}/v1',
                path: {
                    userId: userId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 플랜 주문 이력 엑셀 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadPlanOrderHistories(
    {
        startDate,
        endDate,
    }: {
        startDate: string;
        endDate: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/orders/histories/excel/plans/v1',
                query: {
                    startDate: startDate,
                    endDate: endDate,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 크레딧 주문 이력 엑셀 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadCreditOrderHistories(
    {
        startDate,
        endDate,
    }: {
        startDate: string;
        endDate: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/orders/histories/excel/credits/v1',
                query: {
                    startDate: startDate,
                    endDate: endDate,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 상세 조회
 * @returns MtmLissResponseWorkspaceDetailsDto OK
 * @throws ApiError
 */
export function getWorkspaceSpecification(
    {
        id,
    }: {
        id: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseWorkspaceDetailsDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/workspaces/{id}/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateWorkspace(
    {
        id,
        requestBody,
    }: {
        id: number;
        requestBody: MtmWorkspaceCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/workspaces/{id}/v1',
                path: {
                    id: id,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 설정 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateSuperAdminWorkspaceConfiguration(
    {
        requestBody,
    }: {
        requestBody: MtmSuperAdminWorkspaceConfigurationUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/workspace-configurations/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 설정 조회
 * @returns MtmLissResponseWorkspaceConfigDto OK
 * @throws ApiError
 */
export function getWorkspaceConfig(
    {
        tenantId,
        workspaceId,
    }: {
        tenantId: number;
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseWorkspaceConfigDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/workspace-configs/v1',
                query: {
                    tenantId: tenantId,
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 설정 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateWorkspaceConfig(
    {
        requestBody,
    }: {
        requestBody: MtmSuperAdminWorkspaceConfigUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/workspace-configs/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 약관 상세 조회
 * @returns MtmLissResponseTermDto OK
 * @throws ApiError
 */
export function getTerm(
    {
        id,
    }: {
        id: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseTermDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/terms/{id}/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 약관 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateTerm(
    {
        id,
        requestBody,
    }: {
        id: number;
        requestBody: MtmTermUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/terms/{id}/v1',
                path: {
                    id: id,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 약관 삭제
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deleteTerm(
    {
        id,
    }: {
        id: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/super-admin/terms/{id}/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 구독 기간 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function updateSubscriptionRange(
    {
        subscriptionId,
        requestBody,
    }: {
        subscriptionId: number;
        requestBody: MtmSubscriptionRangeUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/subscriptions/{subscriptionId}/ranges/v1',
                path: {
                    subscriptionId: subscriptionId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 구독 플랜/라이선스 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function modifyPlanWithLicense(
    {
        subscriptionId,
        requestBody,
    }: {
        subscriptionId: number;
        requestBody: MtmPlanLicenseUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/subscriptions/{subscriptionId}/plan-licenses/v1',
                path: {
                    subscriptionId: subscriptionId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 구독 만료
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function expireSubscription(
    {
        subscriptionId,
    }: {
        subscriptionId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/subscriptions/{subscriptionId}/expire/v1',
                path: {
                    subscriptionId: subscriptionId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 테넌트 플랜 금액 조회
 * @returns MtmLissResponseSimplifiedPlanPricesDto OK
 * @throws ApiError
 */
export function getTenantPlanPrices(
    {
        tenantId,
    }: {
        tenantId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseSimplifiedPlanPricesDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/plans/prices/v1',
                query: {
                    tenantId: tenantId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 테넌트 플랜 금액 설정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function putPlanPrices(
    {
        requestBody,
    }: {
        requestBody: MtmPlanPriceUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/plans/prices/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 테넌트 플랜 금액 기한 만료
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function expirePlanPrices(
    {
        tenantId,
    }: {
        tenantId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/super-admin/plans/prices/v1',
                query: {
                    tenantId: tenantId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 내 유저 라이센스 할당 리스트 조회
 * @returns MtmLissResponseListUserWorkspaceLicenseDto OK
 * @throws ApiError
 */
export function superAdminListUsersWithLicense(
    {
        workspaceId,
    }: {
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseListUserWorkspaceLicenseDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/license/users/v1',
                query: {
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 내 유저 라이센스 할당 리스트 수정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function superAdminUpdateUsersLicense(
    {
        tenantId,
        workspaceId,
        requestBody,
    }: {
        tenantId: number;
        workspaceId: number;
        requestBody: MtmUsersLicenseUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/super-admin/license/users/v1',
                query: {
                    tenantId: tenantId,
                    workspaceId: workspaceId,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 페이지 조회 (overview)
 * @returns MtmLissResponsePageWorkspaceOverviewDto OK
 * @throws ApiError
 */
export function pageWorkspaceOverviews(
    {
        page,
        size = 10,
        sort,
        name,
        subscriptionStatus,
        planType,
    }: {
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
        /**
         * 워크스페이스 명
         */
        name?: string;
        /**
         * 구독 상태
         */
        subscriptionStatus?: string;
        /**
         * 플랜 종류
         */
        planType?: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePageWorkspaceOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/workspaces/v1',
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                    name: name,
                    subscriptionStatus: subscriptionStatus,
                    planType: planType,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 생성
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function createWorkspace(
    {
        requestBody,
    }: {
        requestBody: MtmWorkspaceCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admin/workspaces/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * OpenApi key 추가
 * @returns MtmLissResponseApiKeyCreatedDto OK
 * @throws ApiError
 */
export function addOpenApiKey(
    {
        requestBody,
    }: {
        requestBody: MtmSuperAdminOpenApiKeyAddForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseApiKeyCreatedDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admin/workspace-configs/api-keys/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 약관 신규 리비전 생성
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function createTermRevisionV2(
    {
        id,
        formData,
    }: {
        id: number;
        formData?: {
            file: Blob;
            form: MtmTermRevisionCreateFormV2;
        };
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admin/terms/{id}/revisions/v2',
                path: {
                    id: id,
                },
                formData: formData,
                mediaType: 'multipart/form-data',
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 약관 신규 리비전 생성
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function createTermRevision(
    {
        id,
        requestBody,
    }: {
        id: number;
        requestBody: MtmTermRevisionCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admin/terms/{id}/revisions/v1',
                path: {
                    id: id,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 약관 생성
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function createTermV3(
    {
        formData,
    }: {
        formData?: {
            file: Blob;
            form: MtmTermCreateFormV3;
        };
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admin/terms/v3',
                formData: formData,
                mediaType: 'multipart/form-data',
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 약관 생성
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function createTermV2(
    {
        requestBody,
    }: {
        requestBody: MtmTermCreateFormV2;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admin/terms/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 약관 리스트 조회
 * @returns MtmLissResponseListTermOverviewDto OK
 * @throws ApiError
 */
export function listTerms(
    {
        type,
    }: {
        /**
         * 약관 유형
         */
        type?: 'SERVICE' | 'PAYMENT';
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseListTermOverviewDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/terms/v1',
                query: {
                    type: type,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 약관 생성
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function createTerm(
    {
        requestBody,
    }: {
        requestBody: MtmTermCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admin/terms/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 구독 목록 조회
 * @returns MtmLissResponsePageSubscriptionSimpleVo OK
 * @throws ApiError
 */
export function pageSubscriptions(
    {
        tenantId,
        workspaceId,
        pageable,
    }: {
        tenantId: number;
        workspaceId: number;
        pageable: MtmPageable;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePageSubscriptionSimpleVo> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/subscriptions/v1',
                query: {
                    tenantId: tenantId,
                    workspaceId: workspaceId,
                    pageable: pageable,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 구독 추가
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function addSubscription(
    {
        requestBody,
    }: {
        requestBody: MtmSubscriptionAddForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admin/subscriptions/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 크레딧 조정 이력 조회
 * @returns MtmLissResponsePageCreditAdjustmentDto OK
 * @throws ApiError
 */
export function pageCreditAdjustments(
    {
        tenantId,
        workspaceId,
        pageable,
    }: {
        tenantId: number;
        workspaceId: number;
        pageable: MtmPageable;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePageCreditAdjustmentDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/credits/adjustments/v1',
                query: {
                    tenantId: tenantId,
                    workspaceId: workspaceId,
                    pageable: pageable,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 크레딧 조정
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function adjustCredits(
    {
        requestBody,
    }: {
        requestBody: MtmCreditAdjustmentForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/super-admin/credits/adjustments/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스내 사용자 목록 페이징 조회
 * @returns MtmLissResponsePageUserDtoV2 OK
 * @throws ApiError
 */
export function pageUsersByWorkspace(
    {
        workspaceId,
        keyword,
        page,
        size = 10,
        sort,
    }: {
        workspaceId: number;
        keyword?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePageUserDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/workspaces/{workspaceId}/users/v1',
                path: {
                    workspaceId: workspaceId,
                },
                query: {
                    keyword: keyword,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 부서/직위/직책/권한 조회
 * @returns MtmLissResponseWorkspaceAffiliationsDto OK
 * @throws ApiError
 */
export function getWorkspaceAffiliations(
    {
        id,
    }: {
        id: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseWorkspaceAffiliationsDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/workspaces/{id}/affiliations/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 페이지 조회 v2 (overview)
 * @returns MtmLissResponsePageWorkspaceOverviewDtoV2 OK
 * @throws ApiError
 */
export function pageWorkspaceOverviewsV2(
    {
        page,
        size = 10,
        sort,
        name,
        subscriptionStatus,
        usageType,
    }: {
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
        /**
         * 워크스페이스 명
         */
        name?: string;
        /**
         * 구독 상태
         */
        subscriptionStatus?: string;
        /**
         * 워크스페이스 유형
         */
        usageType?: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePageWorkspaceOverviewDtoV2> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/workspaces/v2',
                query: {
                    page: page,
                    size: size,
                    sort: sort,
                    name: name,
                    subscriptionStatus: subscriptionStatus,
                    usageType: usageType,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 워크스페이스 검색 (simple)
 * @returns MtmLissResponsePageWorkspaceSimpleDto OK
 * @throws ApiError
 */
export function searchSimpleWorkspace(
    {
        name,
        subscriptionStatus,
        planType,
        page,
        size = 100,
        sort,
    }: {
        /**
         * 워크스페이스 명
         */
        name?: string;
        /**
         * 구독 상태
         */
        subscriptionStatus?: string;
        /**
         * 플랜 종류
         */
        planType?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePageWorkspaceSimpleDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/workspaces/simple-search/v1',
                query: {
                    name: name,
                    subscriptionStatus: subscriptionStatus,
                    planType: planType,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 슈퍼어드민이 설정 가능한 목록 조회
 * @returns MtmLissResponseListWorkspaceConfigurationDto OK
 * @throws ApiError
 */
export function listSuperAdminManagedWorkspaceConfigurations(
    {
        workspaceId,
    }: {
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseListWorkspaceConfigurationDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/workspace-configurations/managed/v1',
                query: {
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 약관 파일 다운로드
 * @returns binary OK
 * @throws ApiError
 */
export function downloadTermRevisionFile(
    {
        termId,
        revisionId,
    }: {
        termId: number;
        revisionId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<Blob> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/terms/{termId}/revisions/{revisionId}/download/v1',
                path: {
                    termId: termId,
                    revisionId: revisionId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 현재 구독 정보 조회
 * @returns MtmLissResponseSubscriptionInfoDto OK
 * @throws ApiError
 */
export function getCurrentSubscriptionSuperAdmin(
    {
        tenantId,
        workspaceId,
    }: {
        tenantId: number;
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseSubscriptionInfoDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/subscriptions/current/v1',
                query: {
                    tenantId: tenantId,
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 플랜 리스트 조회
 * @returns MtmLissResponseListPlanDto OK
 * @throws ApiError
 */
export function listPlans(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseListPlanDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/plans/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 플랜 주문 이력 조회
 * @returns MtmLissResponsePageOrderHistoryDto OK
 * @throws ApiError
 */
export function getOrderHistoriesSuperAdmin(
    {
        tenantId,
        pageable,
    }: {
        tenantId: number;
        pageable: MtmPageable;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePageOrderHistoryDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/plans/order-histories/v1',
                query: {
                    tenantId: tenantId,
                    pageable: pageable,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 라이선스 수 및 할당 유저 수 조회 API
 * @returns MtmLissResponseLicenseUserCountDto OK
 * @throws ApiError
 */
export function countLicenseAndUsersBy(
    {
        tenantId,
        workspaceId,
    }: {
        tenantId: number;
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseLicenseUserCountDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/license/user-count/v1',
                query: {
                    tenantId: tenantId,
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 크레딧 사용량 메타데이터 v1
 * @returns MtmLissResponseMapStringObject OK
 * @throws ApiError
 */
export function getCreditStatisticsMetaSuperAdmin(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseMapStringObject> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/credits/statistics/meta/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자별 크레딧 사용량 통계 조회 v1
 * @returns MtmLissResponsePageCreditStatisticByUserDto OK
 * @throws ApiError
 */
export function pageCreditStatisticsByUsersSuperAdmin(
    {
        tenantId,
        workspaceId,
        yearMonth,
        page,
        size = 30,
        sort,
    }: {
        tenantId: number;
        workspaceId: number;
        /**
         * 조회 대상 년월 (yyyy-MM)
         */
        yearMonth?: string;
        /**
         * Zero-based page index (0..N)
         */
        page?: number;
        /**
         * The size of the page to be returned
         */
        size?: number;
        /**
         * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         */
        sort?: Array<string>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePageCreditStatisticByUserDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/credits/statistics/by-user/v1',
                query: {
                    tenantId: tenantId,
                    workspaceId: workspaceId,
                    yearMonth: yearMonth,
                    page: page,
                    size: size,
                    sort: sort,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 서비스별 크레딧 사용량 통계 조회 v1
 * @returns MtmLissResponseListCreditStatisticsByServiceDto OK
 * @throws ApiError
 */
export function listCreditStatisticsByServicesSuperAdmin(
    {
        tenantId,
        workspaceId,
        yearMonth,
    }: {
        tenantId: number;
        workspaceId: number;
        /**
         * 조회 대상 년월 (yyyy-MM)
         */
        yearMonth?: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseListCreditStatisticsByServiceDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/credits/statistics/by-service/v1',
                query: {
                    tenantId: tenantId,
                    workspaceId: workspaceId,
                    yearMonth: yearMonth,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 크레딧 주문 이력 조회
 * @returns MtmLissResponsePageCreditOrderHistoryDto OK
 * @throws ApiError
 */
export function pageCreditOrderHistoriesSuperAdmin(
    {
        tenantId,
        workspaceId,
        pageable,
    }: {
        tenantId: number;
        workspaceId: number;
        pageable: MtmPageable;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponsePageCreditOrderHistoryDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/credits/order-histories/v1',
                query: {
                    tenantId: tenantId,
                    workspaceId: workspaceId,
                    pageable: pageable,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 크레딧 사용량 엑셀 다운로드
 * @returns any OK
 * @throws ApiError
 */
export function downloadCreditMonthlyUsageExcel(
    {
        startYearMonth,
        endYearMonth,
    }: {
        /**
         * 조회 시작 년월
         */
        startYearMonth?: string;
        /**
         * 조회 종료 년월
         */
        endYearMonth?: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<any> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/credits/monthly-usage/excel/v1',
                query: {
                    startYearMonth: startYearMonth,
                    endYearMonth: endYearMonth,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 이번달 사용 크레딧 조회
 * @returns MtmLissResponseCreditsDto OK
 * @throws ApiError
 */
export function getMonthlyConsumedCreditsSuperAdmin(
    {
        tenantId,
        workspaceId,
    }: {
        tenantId: number;
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseCreditsDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/credits/monthly-consumed/v1',
                query: {
                    tenantId: tenantId,
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 크레딧 관련 Metadata 조회
 * @returns MtmLissResponseMapStringListObject OK
 * @throws ApiError
 */
export function listMetadata(_?: any, requestConfig?: RequestConfig): AxiosPromise<MtmLissResponseMapStringListObject> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/credits/meta/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 크레딧 잔액 조회
 * @returns MtmLissResponseCreditBalanceDto OK
 * @throws ApiError
 */
export function getCreditBalanceSuperAdmin(
    {
        tenantId,
        workspaceId,
    }: {
        tenantId: number;
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseCreditBalanceDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/credits/balance/v1',
                query: {
                    tenantId: tenantId,
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 크레딧 타입별 잔액 조회
 * @returns MtmLissResponseListCreditBalanceByTypeDto OK
 * @throws ApiError
 */
export function getCreditBalanceSuperAdminByType(
    {
        tenantId,
        workspaceId,
    }: {
        tenantId: number;
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponseListCreditBalanceByTypeDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/super-admin/credits/balance/by-type/v1',
                query: {
                    tenantId: tenantId,
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 약관 리비전 삭제
 * @returns MtmLissResponse OK
 * @throws ApiError
 */
export function deleteTermRevision(
    {
        id,
    }: {
        id: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<MtmLissResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/super-admin/terms/revisions/{id}/v1',
                path: {
                    id: id,
                },
            }),
            requestConfig,
        ),
    );
}
