import { Extension } from '@tiptap/core';
import { wordStarterPackExtensions } from './wordStarterPackExtensions';

export interface WordStarterPackOptions {
    [key: string]: unknown;
}

const StandardFormWordStarterPack = Extension.create<WordStarterPackOptions>({
    name: 'wordStarterPack',

    addExtensions() {
        return wordStarterPackExtensions;
    },
});

export default StandardFormWordStarterPack;
