import { Editor } from 'svelte-tiptap';
import { Slice, Fragment, NodeType, Node } from '@tiptap/pm/model';
import { cloneDeep, uniq } from 'lodash-es';

export type SlicedContent = { start: number; end: number; slice: any; content: Record<string, any>[]; openStart: number; openEnd: number };
export interface EditorUtils {
    updateAllHighlighting(positions: { id: string; start: number; end: number }[]): void;
    updateAllHighlightingWithGuid(positions: { id?: string; guid: string; start: number; end: number }[]): void;
    updateAllHighlightingWithGuidRange(position: { id?: string; startGuid: string; endGuid: string }): void;
    setHighlightingWithTextContent(text: string): Promise<{ id: string; from: number; to: number }>;
    getContentsJsonWithGuids(startGuid: string, endGuid: string): SlicedContent;
    replaceContentWithGuids(startGuid: string, endGuid: string, replacedContent: Record<string, any>[]): (SlicedContent & { replacedStartGuid: string; replacedEndGuid: string }) | undefined;
    replaceContentWithSelection(replacedContent: Record<string, any>[]): SlicedContent & { replacedStartGuid: string; replacedEndGuid: string };
}
declare module '@tiptap/core' {
    interface Editor {
        initializeUtils(): void;
        utils: EditorUtils;
    }
}

/**
 * ProseMirror JSON에서 guid를 교체하는 함수
 * @param json ProseMirror JSON
 * @param targetGuid 업데이트할 노드의 guid
 * @param newGuid 새로운 guid
 * @returns 업데이트된 ProseMirror JSON
 * @example
 * const updatedJSON = replaceGuidInProseMirrorJSON(json, targetGuid, newGuid);
 */
export function replaceGuidInProseMirrorJSON(data: any, targetGuid: string, newGuid: string) {
    // 노드의 guid 속성을 업데이트하는 재귀 함수
    function updateNode(node) {
        // attrs.guid가 targetGuid와 일치하면 새로운 guid로 교체
        if (node.attrs && node.attrs.guid === targetGuid) {
            node.attrs.guid = newGuid;
        }

        // 자식 노드가 있는 경우 재귀적으로 처리
        if (node.content && Array.isArray(node.content)) {
            node.content = node.content.map(updateNode);
        }

        return node;
    }

    // 데이터가 doc 타입인지 content 배열인지 확인
    if (Array.isArray(data)) {
        return data.map(updateNode);
    } else if (data.type === 'doc' && Array.isArray(data.content)) {
        data.content = data.content.map(updateNode);
        return data;
    } else {
        throw new Error('Invalid input format. Expected a doc or content array.');
    }
}

/**
 * ProseMirror JSON에서 guid를 갱신하는 함수
 * @param json ProseMirror JSON
 * @returns 갱신된 ProseMirror JSON
 * @example
 * const updatedJSON = renewGuidInProseMirrorJSON(json);
 */
export function renewGuidInProseMirrorJSON(data: any) {
    // 노드의 guid 속성을 업데이트하는 재귀 함수
    function updateNode(node) {
        if (node.attrs && node.attrs.guid) {
            node.attrs.guid = crypto.randomUUID();
        }

        // 자식 노드가 있는 경우 재귀적으로 처리
        if (node.content && Array.isArray(node.content)) {
            node.content = node.content.map(updateNode);
        }

        return node;
    }

    // 데이터가 doc 타입인지 content 배열인지 확인
    if (Array.isArray(data)) {
        return data.map(updateNode);
    } else if (data.type === 'doc' && Array.isArray(data.content)) {
        data.content = data.content.map(updateNode);
        return data;
    } else {
        throw new Error('Invalid input format. Expected a doc or content array.');
    }
}

export function setPrototypeOfEditorUtils() {
    // Editor extension의 Return은 ReturnType만을 받다보니 실제 데이터 리턴을 처리하기 위해 별도의 prototype으로 처리
    Editor.prototype.initializeUtils = function () {
        this.utils = {
            updateAllHighlighting: (positions: { id: string; start: number; end: number }[]) => {
                this.commands.removeAllHighlighting();
                positions.forEach(({ id, start, end }) => {
                    this.commands.addHighlightingWithTextPosition(id, start, end, {
                        className: 'bg-none',
                        focusedClassName: 'bg-purple-200 text-gray-900',
                    });
                });
                this.commands.setFocusHighlighting(positions[0]?.id);
                setTimeout(() => {
                    this.commands.scrollIntoFocusedHighlighting();
                }, 0);
            },
            updateAllHighlightingWithGuid: (positions: { id?: string; guid: string; start: number; end: number }[]) => {
                this.commands.removeAllHighlighting();
                let firstNewId;
                positions.forEach(({ id, guid, start, end }, idx) => {
                    const newId = id || crypto.randomUUID();
                    if (idx == 0) firstNewId = newId;
                    this.commands.addHighlightingWithGuid(newId, guid, {
                        startInlineOffset: start,
                        endInlineOffset: end,
                        className: 'bg-none',
                        focusedClassName: 'bg-purple-200 text-gray-900',
                    });
                });
                this.commands.setFocusHighlighting(firstNewId);
                setTimeout(() => {
                    this.commands.scrollIntoFocusedHighlighting();
                }, 0);
            },
            updateAllHighlightingWithGuidRange: ({ id, startGuid, endGuid }) => {
                this.commands.removeAllHighlighting();
                const newId = id || crypto.randomUUID();
                this.commands.addHighlightingWithGuidRange(newId, startGuid, endGuid, {
                    className: 'bg-none',
                    focusedClassName: 'bg-purple-200 text-gray-900',
                });
                this.commands.setFocusHighlighting(newId);
                setTimeout(() => {
                    this.commands.scrollIntoFocusedHighlighting();
                }, 0);
            },
            // deprecated
            setHighlightingWithTextContent: async text => {
                this.commands.removeAllHighlighting();
                const promise = new Promise((resolve, reject) => {
                    this.commands.addHighlightingWithTextContent(text, {
                        className: 'bg-none',
                        focusedClassName: 'bg-purple-200 text-gray-900',
                        onAddFinish: ({ id, from, to }) => {
                            resolve({ id, from, to });
                        },
                    });
                });
                const { id, from, to } = (await promise) as { id: string; from: number; to: number };
                if (id) {
                    this.commands.setFocusHighlighting(id as string);
                    setTimeout(() => {
                        this.commands.scrollIntoFocusedHighlighting();
                    }, 0);
                }
                return { id, from, to };
            },
            /**
             * @description startGuid와 endGuid 사이의 JSON 데이터를 가져온다.
             * @param startGuid 시작지점이 되는 guid
             * @param endGuid 끝지점이 되는 guid
             * @returns JSON 리턴값은 slice 객체를 리턴한다. 이 객체는 openStart와 openEnd 를 포함하고 있고 최상위를 fragment로 감싸서 리턴한다.
             */
            getContentsJsonWithGuids: (startGuid, endGuid) => {
                let startNode;
                let startNodePos;
                let endNode;
                let endNodePos;
                this.state.doc.descendants((node, pos) => {
                    if (node.attrs.guid === startGuid) {
                        startNode = node;
                        startNodePos = pos;
                    }
                    if (node.attrs.guid === endGuid) {
                        endNode = node;
                        endNodePos = pos;
                    }
                });
                if (!startNode || !endNode) {
                    console.warn('guid에 해당하는 node를 발견하지 못했습니다.', startGuid, endGuid, startNode, endNode);
                    return {
                        start: 0,
                        end: 0,
                        slice: null,
                        content: [],
                        openStart: 0,
                        openEnd: 0,
                    };
                }
                const slice = this.state.doc.slice(startNodePos, endNodePos + endNode.nodeSize, false);
                return {
                    start: startNodePos,
                    end: endNodePos + endNode.nodeSize,
                    slice,
                    content: slice.content.toJSON(),
                    openStart: slice.openStart,
                    openEnd: slice.openEnd,
                };
            },
            /**
             * @description startGuid와 endGuid 사이의 JSON 데이터를 replacedContent로 교체한다.
             * @param startGuid 시작지점이 되는 guid
             * @param endGuid 끝지점이 되는 guid
             * @param replacedContent 교체할 JSON 배열 데이터
             * @returns 교체된 JSON 데이터를 리턴한다.
             */
            replaceContentWithGuids: (startGuid, endGuid, replacedContent) => {
                const { start, end, slice, content, openStart, openEnd } = this.utils.getContentsJsonWithGuids(startGuid, endGuid);
                if (!slice) {
                    console.warn('guid에 해당하는 범위를 찾지 못했습니다.', startGuid, endGuid);
                    return;
                }
                // 치환되어질 guid들과 치환할 guid 목록을 만들어서 같지 않으면 새로운 guid를 부여한다.
                const originGuids: string[] = [];
                const replacedGuids: string[] = [];
                slice.content.descendants((node, pos) => {
                    if (node.attrs.guid) {
                        originGuids.push(node.attrs.guid);
                    }
                });
                let fragment = Fragment.fromJSON(this.state.schema, replacedContent);
                const willChangeGuids: string[] = [];
                fragment.descendants((node, pos) => {
                    if (node.attrs.guid && !originGuids.includes(node.attrs.guid)) {
                        willChangeGuids.push(node.attrs.guid);
                    }
                });
                if (willChangeGuids.length) {
                    let nextContent = cloneDeep(replacedContent);
                    willChangeGuids.forEach(guid => {
                        nextContent = replaceGuidInProseMirrorJSON(nextContent, guid, crypto.randomUUID());
                    });
                    fragment = Fragment.fromJSON(this.state.schema, nextContent);
                }
                fragment.descendants((node, pos) => {
                    if (node.attrs.guid) {
                        replacedGuids.push(node.attrs.guid);
                    }
                });
                if (uniq(replacedGuids).length !== replacedGuids.length) {
                    console.warn('교체된 데이터에 중복된 guid가 존재합니다.', replacedGuids);
                }
                const newSlice = new Slice(fragment, 0, 0);
                const newTr = this.view.state.tr.replace(start - openStart, end + openEnd, newSlice);
                this.view.dispatch(newTr);

                return {
                    ...this.utils.getContentsJsonWithGuids(replacedGuids[0], replacedGuids[replacedGuids.length - 1]),
                    replacedStartGuid: replacedGuids[0],
                    replacedEndGuid: replacedGuids[replacedGuids.length - 1],
                };
            },
            /**
             * @description 현재 선택된 영역을 replacedContent로 교체한다.
             * @param replacedContent 교체할 JSON 배열 데이터
             */
            replaceContentWithSelection: replacedContent => {
                const { from, to } = this.state.selection;
                // selection으로 선택된 영역의 경우 guid가 앞에서 잘려서 들어가게 되므로 guid를 재생성해야 한다.
                const replacedGuids: string[] = [];
                const fragment = Fragment.fromJSON(this.state.schema, renewGuidInProseMirrorJSON(replacedContent));
                fragment.descendants((node, pos) => {
                    if (node.attrs.guid) {
                        replacedGuids.push(node.attrs.guid);
                    }
                });
                const newSlice = new Slice(fragment, 0, 0);
                const newTr = this.view.state.tr.replace(from, to, newSlice);
                this.view.dispatch(newTr);
                return {
                    ...this.utils.getContentsJsonWithGuids(replacedGuids[0], replacedGuids[replacedGuids.length - 1]),
                    replacedStartGuid: replacedGuids[0],
                    replacedEndGuid: replacedGuids[replacedGuids.length - 1],
                };
            },
        };
    };
}

/**
 * 현재 선택된 영역에 특정 노드 타입의 노드를 찾아서 callback 함수에 전달하면서 호출한다.
 */
export function selectionHas(editor: Editor, nodeType: NodeType, callback: (has: boolean) => void) {
    const { doc, selection } = editor.state;

    let found = false;
    doc.nodesBetween(selection.from, selection.to, node => {
        if (node.type === nodeType) {
            found = true;
            return false; // 찾았으면 더 이상 찾지 않음
        }
        return true;
    });
    callback(found);
}
