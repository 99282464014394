<script lang="ts">
    import { twMerge } from 'tailwind-merge';
    import type { TemplateFieldType } from '../types';
    import { templateFieldIconMap } from './icons';

    export let fieldType: TemplateFieldType | undefined;
    export let selected: boolean;
    export let focused: boolean;

    $: Icon = fieldType ? templateFieldIconMap[fieldType]?.Icon : undefined;
</script>

<span
    {...$$props}
    class={twMerge('w-max items-center rounded bg-indigo-100 px-[.3rem] py-[0.2rem] text-left font-semibold', $$props.class)}
    class:bg-indigo-300={selected}
    class:bg-yellow-100={!fieldType}
    class:text-indigo-800={!focused}
    class:bg-orange-300={focused}
    class:text-yellow-900={!fieldType}
    class:ring-orange-300={focused}
    class:ring={selected}
>
    {#if Icon}
        <Icon class="inline -translate-y-0.5" />
    {/if}
    <slot />
</span>
