<script lang="ts">
    import { Tooltip } from '@allibee/components/basic';
    import type { NodeViewProps } from '@tiptap/core';
    import { onMount } from 'svelte';

    import { NodeViewContent, NodeViewWrapper } from 'svelte-tiptap';
    import { twMerge } from 'tailwind-merge';

    export let node: NodeViewProps['node'];
    export let deleteNode: NodeViewProps['deleteNode'];
    export let editor: NodeViewProps['editor'];
    export let getPos: NodeViewProps['getPos'];

    $$props;

    $: [nodeName, label] = node.attrs['data-name'].split('-');

    let isCursorWithin: boolean;

    const updateCursorWithin = () => {
        const nodePos = getPos();

        const nodeStart = nodePos;
        const nodeEnd = nodePos + node.nodeSize;
        const cursorPos = editor.state.selection.$from.pos;

        isCursorWithin = cursorPos >= nodeStart && cursorPos <= nodeEnd;
    };

    onMount(() => {
        editor.on('selectionUpdate', updateCursorWithin);
        return () => editor.off('selectionUpdate', updateCursorWithin);
    });
</script>

<NodeViewWrapper
    class={twMerge(
        'svelte-component my-1 flex flex-col gap-1 rounded bg-green-50 p-1',
        isCursorWithin && 'bg-green-100',
        !node.attrs['data-removable'] && 'bg-yellow-50',
        node.attrs['data-temporary-dismiss'] && 'hidden',
    )}
    draggable="true"
    data-drag-handle
>
    <div class={twMerge('flex items-center gap-1 text-xs font-semibold', node.attrs['data-removable'] ? 'cursor-move text-green-600' : 'text-yellow-900')} contenteditable="false">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.76172 9.69727V2.25H9.53455V3.3712H6.09894V5.40789H9.20539V6.52909H6.09894V9.69727H4.76172Z" fill="currentColor" />
            <path d="M3.37237 2.25V9.69727H2.03516V2.25H3.37237Z" fill="currentColor" />
        </svg>
        {nodeName}
        {#if label}
            =
            <svg class="inline-block" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.1575 7.72208C10.3836 7.17611 10.5 6.59095 10.5 6C10.5 4.80653 10.0259 3.66193 9.18198 2.81802C8.33807 1.97411 7.19347 1.5 6 1.5C4.80653 1.5 3.66193 1.97411 2.81802 2.81802C1.97411 3.66193 1.5 4.80653 1.5 6C1.5 6.59095 1.6164 7.17611 1.84254 7.72208C2.06869 8.26804 2.40016 8.76412 2.81802 9.18198C3.23588 9.59984 3.73196 9.93131 4.27792 10.1575C4.82389 10.3836 5.40905 10.5 6 10.5C6.59095 10.5 7.17611 10.3836 7.72208 10.1575C8.26804 9.93131 8.76412 9.59984 9.18198 9.18198C9.59984 8.76412 9.93131 8.26804 10.1575 7.72208Z"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path d="M6 8.5C7.38071 8.5 8.5 7.38071 8.5 6C8.5 4.61929 7.38071 3.5 6 3.5C4.61929 3.5 3.5 4.61929 3.5 6C3.5 7.38071 4.61929 8.5 6 8.5Z" fill="currentColor" />
            </svg>
            {label}
        {/if}
        {#if node.attrs['data-removable']}
            <button class="ml-auto flex h-7 w-7 items-center justify-center gap-1 rounded border-[3px] border-transparent hover:border-gray-200" on:click={deleteNode}>
                <svg width="14" height="14" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.5 8.25V12.75M10.5 8.25V12.75M3 5.25H15M14.25 5.25L13.5997 14.3565C13.5728 14.7349 13.4035 15.0891 13.1258 15.3477C12.8482 15.6063 12.4829 15.75 12.1035 15.75H5.8965C5.5171 15.75 5.1518 15.6063 4.87416 15.3477C4.59653 15.0891 4.42719 14.7349 4.40025 14.3565L3.75 5.25H14.25ZM11.25 5.25V3C11.25 2.80109 11.171 2.61032 11.0303 2.46967C10.8897 2.32902 10.6989 2.25 10.5 2.25H7.5C7.30109 2.25 7.11032 2.32902 6.96967 2.46967C6.82902 2.61032 6.75 2.80109 6.75 3V5.25H11.25Z"
                        stroke="#4B5563"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </button>
            <Tooltip>블록 삭제</Tooltip>
        {/if}
    </div>
    <NodeViewContent as="span" class="bhsn-block-reference flex cursor-text">
        {#if node.textContent === ''}
            <span class="font-sm left-0 top-0 text-gray-500">등록된 본문 내용이 없습니다.</span>
        {/if}
    </NodeViewContent>
</NodeViewWrapper>

<style>
    :global(.bhsn-block-reference .ProseMirror-gapcursor:after) {
        border-top: transparent !important;
        height: 1.3rem;
        top: 0px;
        left: 1px;
        border-left: 1px solid #000;
    }
</style>
