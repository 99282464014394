<script lang="ts">
    import { TemplateFieldComponentLayout } from '@allibee/components/common';
    import type { NodeViewProps } from '@tiptap/core';
    import { NodeViewContent, NodeViewWrapper } from 'svelte-tiptap';
    import { twMerge } from 'tailwind-merge';

    export let node: NodeViewProps['node'];
    export let selected: NodeViewProps['selected'];
    $$restProps;
</script>

<NodeViewWrapper class={twMerge('svelte-component inline', selected && 'selected')}>
    <span class="bhsn-reference">
        <span />
        <TemplateFieldComponentLayout
            class={twMerge('w-max items-center rounded bg-indigo-100 px-[.3rem] py-[0.2rem] text-left font-semibold', $$props.class)}
            fieldType={node.attrs['data-type']}
            {selected}
            focused={node.attrs['focused-input']}
            data-drag-handle
            contenteditable="false"
        >
            {#if !node.content.size}
                {node.attrs['data-name']}
            {/if}
            <NodeViewContent as="span" />
        </TemplateFieldComponentLayout>
        <span />
    </span>
</NodeViewWrapper>
